import React, { useState, useEffect } from 'react';
import { Card, CardContent } from "@/components/ui/card";

interface CountdownTimerProps {
  expirationDate: string | null;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({ expirationDate }) => {
  const [remainingTime, setRemainingTime] = useState<number | null>(() => {
    if (!expirationDate) return null;
    const now = new Date().getTime();
    const expirationTime = new Date(expirationDate).getTime();
    return Math.max(0, Math.floor((expirationTime - now) / 1000));
  });

  useEffect(() => {
    if (!expirationDate) return;

    const calculateRemainingTime = (): number => {
      const now = new Date().getTime();
      const expirationTime = new Date(expirationDate).getTime();
      return Math.max(0, Math.floor((expirationTime - now) / 1000));
    };

    const timer = setInterval(() => {
      const newRemainingTime = calculateRemainingTime();
      setRemainingTime(newRemainingTime);

      if (newRemainingTime <= 0) {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [expirationDate]);

  if (remainingTime === null) {
    return (
      <Card className="bg-primary text-primary-foreground">
        <CardContent className="p-4">
          <div className="text-center">
            <h2 className="text-lg font-semibold">Indefinite Poll</h2>
            <p>This poll has no expiration date.</p>
          </div>
        </CardContent>
      </Card>
    );
  }

  if (remainingTime <= 0) {
    return (
      <Card className="bg-primary text-primary-foreground">
        <CardContent className="p-4">
          <div className="text-center">
            <h2 className="text-lg font-semibold">Poll Ended</h2>
          </div>
        </CardContent>
      </Card>
    );
  }

  const days = Math.floor(remainingTime / (24 * 60 * 60));
  const hours = Math.floor((remainingTime % (24 * 60 * 60)) / (60 * 60));
  const minutes = Math.floor((remainingTime % (60 * 60)) / 60);
  const seconds = remainingTime % 60;

  return (
    <Card className="bg-primary text-primary-foreground">
      <CardContent className="p-4">
        <div className="text-center">
          <h2 className="text-lg font-semibold mb-2">Poll Ends In:</h2>
          <div className="flex justify-center items-center space-x-4">
            <TimeUnit value={days} label="Days" />
            <TimeUnit value={hours} label="Hours" />
            <TimeUnit value={minutes} label="Minutes" />
            <TimeUnit value={seconds} label="Seconds" />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

interface TimeUnitProps {
  value: number;
  label: string;
}

const TimeUnit: React.FC<TimeUnitProps> = ({ value, label }) => (
  <div className="flex flex-col items-center">
    <span className="text-2xl font-bold">{value.toString().padStart(2, '0')}</span>
    <span className="text-xs">{label}</span>
  </div>
);

export default CountdownTimer;