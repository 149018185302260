import React from 'react';
import '@/styles/globals.css';
import { siX, siFacebook, siInstagram, siTiktok } from 'simple-icons';

const Footer: React.FC = () => {
  const SocialIcon: React.FC<{ icon: any, href: string }> = ({ icon, href }) => (
    <a href={href} target="_blank" rel="noopener noreferrer" className="text-foreground hover:opacity-80 transition-opacity">
      <svg
        role="img"
        viewBox="0 0 24 24"
        className="w-6 h-6"
        fill="currentColor"
      >
        <path d={icon.path} />
      </svg>
    </a>
  );

  return (
    <footer className="flex flex-col items-center p-5 gap-5 w-full max-w-screen-xl mx-auto">
      <div className="flex flex-row items-center justify-between w-full">
        <a href="/" className="text-8xl font-bold text-foreground hover:opacity-80 transition-opacity no-underline">
          Picpol
        </a>
        <div className="flex flex-row items-center gap-8">
          <a href="./about" className="text-sm font-semibold text-foreground p-0">
            About
          </a>
          <div className="flex flex-row justify-end items-center gap-3">
            {/* Social Links */}
            <SocialIcon icon={siFacebook} href="https://www.facebook.com/picpolcom" />
            <SocialIcon icon={siX} href="https://www.twitter.com/picpolcom" />
            <SocialIcon icon={siInstagram} href="https://www.instagram.com/picpolcom" />
            <SocialIcon icon={siTiktok} href="https://www.tiktok.com/@picpolcom" />
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center gap-5 w-full">
        {/* Divider */}
        <div className="w-full h-px bg-foreground"></div>
        <div className="flex flex-row justify-between items-center w-full">
          <span className="text-xs text-muted-foreground">© 2024 Picpol. All rights reserved.</span>
          <div className="flex flex-row items-end gap-6">
            <a href="./PP" className="text-xs font-semibold text-foreground underline p-0">
              Privacy Policy
            </a>
            <a href="./TOS" className="text-xs font-semibold text-foreground underline p-0">
              Terms of Service
            </a>
            <a href="./cookies" className="text-xs font-semibold text-foreground underline p-0">
              Cookies Settings
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;