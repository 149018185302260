import React, { useEffect, useRef, useState } from 'react';
import { toPng } from 'html-to-image';
import { PieChart, Pie, BarChart, Bar, ResponsiveContainer, Cell, CartesianGrid, LabelList } from "recharts";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { TrendingUp } from "lucide-react";
import { ChartDataItem } from '@/types/types';
import { formatNumber, calculatePercentage } from '@/utils/chartUtils';

interface ImageGeneratorProps {
  pollName: string;
  selectedCriterion: string;
  chartType: 'pie' | 'bar';
  chartData: ChartDataItem[];
  COLORS: string[];
  onImageGenerated: (imageUrl: string) => void;
  onError: () => void;
}

const ImageGenerator: React.FC<ImageGeneratorProps> = ({
  pollName,
  selectedCriterion,
  chartType,
  chartData,
  COLORS,
  onImageGenerated,
  onError
}) => {
  const resultCardRef = useRef<HTMLDivElement>(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsReady(true);
    }, 1000);  // 1 second delay

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const generateImage = async () => {
      if (isReady && resultCardRef.current) {
        try {
          const dataUrl = await toPng(resultCardRef.current, {
            quality: 0.95,
            pixelRatio: 2,
            width: 896,
            height: resultCardRef.current.offsetHeight
          });
          onImageGenerated(dataUrl);
        } catch (error) {
          console.error('Error generating image:', error);
          onError();
        }
      }
    };

    generateImage();
  }, [isReady, onImageGenerated, onError]);

  const renderCustomizedLabel = (props: any) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, outerRadius, fill, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333" fontSize={12} fontWeight="bold">
          {`${value} (${(percent * 100).toFixed(0)}%)`}
        </text>
      </g>
    );
  };

  const total = chartData.reduce((sum, item) => sum + ((item[selectedCriterion] as number) || 0), 0);

  const renderChart = () => {
    switch (chartType) {
      case 'bar':
        return (
          <ResponsiveContainer height={270}>
            <BarChart data={chartData} margin={{ left: 0, right: 0, top: 20, bottom: 0 }}>
              <CartesianGrid vertical={false} />
              <Bar dataKey={selectedCriterion} fill={COLORS[0]} radius={[4, 4, 0, 0]}>
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
                <LabelList
                  dataKey={selectedCriterion}
                  position="top"
                  content={(props: any) => {
                    const { x, y, width, value } = props;
                    if (typeof x !== 'number' || typeof y !== 'number' || typeof width !== 'number' || value === undefined) {
                      return null;
                    }
                    const percentage = ((value as number) / total * 100).toFixed(1);
                    return (
                      <text 
                        x={x + (width / 2)} 
                        y={y - 10} 
                        fill="#000000" 
                        textAnchor="middle" 
                        dominantBaseline="middle" 
                        fontSize={12}
                        fontWeight="bold"
                      >
                        {`${value} (${percentage}%)`}
                      </text>
                    );
                  }}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        );
      case 'pie':
        return (
          <ResponsiveContainer height={270}>
            <PieChart>
              <Pie
                data={chartData}
                dataKey={selectedCriterion}
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={90}
                label={renderCustomizedLabel}
                labelLine={false}
              >
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        );
    }
  };

  const renderTop3Images = () => {
    const top3Images = chartData
      .sort((a, b) => (b[selectedCriterion] as number) - (a[selectedCriterion] as number))
      .slice(0, 3);

    return top3Images.map((image, index) => (
      <Card key={index} className="flex items-center gap-1 p-1 bg-[#fbbd23] border-black w-full">
        <img src={image.preview} alt={image.name} width={64} height={64} className="rounded object-cover border-2 border-black" />
        <div className="w-full flex flex-col">
          <h4 className="text-lg font-medium">{image.name}</h4>
          <div className="flex items-center justify-between text-sm">
            <span className="text-lg mt-2 text-gray-500">{formatNumber(image[selectedCriterion] as number)} votes</span>
            <span className="text-xl font-bold mt-2">
              {calculatePercentage(image[selectedCriterion] as number, total)}%
            </span>
          </div>
        </div>
      </Card>
    ));
  };

  return (
    <div ref={resultCardRef} className="image-generator-container">
      <Card className="w-full max-w-xl rounded-lg overflow-hidden border-4 border-black">
        <CardHeader className="flex flex-row items-center justify-between bg-[#fbbd23] p-2 m-0">
          <div className="flex flex-col items-start">
            <CardTitle className="text-2xl font-bold">{pollName}</CardTitle>
            <p className="text-sm text-muted-foreground">Showing votes for {selectedCriterion}</p>
          </div>
          <div className="text-5xl font-bold text-black">Picpol</div>
          <div className="flex flex-col items-end">
            <div className="text-sm text-muted-foreground">
            Total votes: {total}
            <TrendingUp className="h-4 w-4" />
            </div>
          </div>
        </CardHeader>
        <CardContent className="grid grid-cols-2 gap-1 bg-[#fbbd23] p-1">
          <div className="col-span-1 bg-white rounded-lg shadow flex items-center justify-center p-0 px-2">
            {renderChart()}
          </div>
          <div className="col-span-1 grid gap-1">
            {renderTop3Images()}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default ImageGenerator;