import React, { useState, useEffect } from 'react';
import { Copy, ExternalLink } from 'lucide-react';
import { usePollCreator } from '@/hooks/PollCreatorLogic';
import { copyToClipboard } from '@/lib/utils';
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { Toaster } from "@/components/ui/toaster";
import Footer from '@/components/layout/Footer';
import UploadModal from '@/components/UploadModal';
import { Features } from '@/components/layout/Features';
import CreatePollSection from '@/components/CreatePollSection';

const PollCreator: React.FC = () => {
  const {
    pollName,
    setPollName,
    pollDescription,
    setPollDescription,
    images,
    setImages,
    scale,
    setScale,
    isDialogOpen,
    setIsDialogOpen,
    shareLink,
    adminLink,
    handleImageNameChange,
    createPoll,
    criteria,
    setCriteria,
    customCriterion,
    setCustomCriterion,
    toggleCriterion,
    addCustomCriterion,
    removeCustomCriterion,
    predefinedCriteria,
    showCriteriaWarning,
    uploadProgress,
    isUploading,
    uploadComplete,
    userPlan,
    toggleAdminMode,
    isLoading,
  } = usePollCreator();

  const { toast } = useToast();
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [adminUsername, setAdminUsername] = useState('');
  const [adminPassword, setAdminPassword] = useState('');

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.shiftKey && event.key === 'A') {
        setShowAdminModal(true);
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleAdminLogin = () => {
    toggleAdminMode(adminUsername, adminPassword);
    setShowAdminModal(false);
    setAdminUsername('');
    setAdminPassword('');
  };

  const handleCreatePoll = () => {
    let isValid = true;

    if (!pollName.trim()) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Please enter a poll name.",
      });
      isValid = false;
    }

    if (criteria.length === 0) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Please select at least one criterion.",
      });
      isValid = false;
    }

    if (images.length === 0) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Please upload at least one image.",
      });
      isValid = false;
    }

    if (userPlan && criteria.length > userPlan.maxVotingCriteria) {
      toast({
        variant: "destructive",
        title: "Error",
        description: `Your plan allows a maximum of ${userPlan.maxVotingCriteria} voting criteria.`,
      });
      isValid = false;
    }

    if (userPlan && images.length > userPlan.maxImagesPerPoll) {
      toast({
        variant: "destructive",
        title: "Error",
        description: `Your plan allows a maximum of ${userPlan.maxImagesPerPoll} images per poll.`,
      });
      isValid = false;
    }

    if (isValid) {
      createPoll();
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!userPlan) {
    return <div>Error: Unable to load user plan.</div>;
  }

  return (
    <>
      <CreatePollSection
        pollName={pollName}
        setPollName={setPollName}
        pollDescription={pollDescription}
        setPollDescription={setPollDescription}
        images={images}
        setImages={setImages}
        scale={scale}
        setScale={setScale}
        criteria={criteria}
        setCriteria={setCriteria}
        customCriterion={customCriterion}
        setCustomCriterion={setCustomCriterion}
        toggleCriterion={toggleCriterion}
        addCustomCriterion={addCustomCriterion}
        removeCustomCriterion={removeCustomCriterion}
        predefinedCriteria={predefinedCriteria}
        showCriteriaWarning={showCriteriaWarning}
        handleImageNameChange={handleImageNameChange}
        handleCreatePoll={handleCreatePoll}
        userPlan={userPlan}
      />
      <UploadModal
        isOpen={isDialogOpen && isUploading}
        onClose={() => setIsDialogOpen(false)}
        uploadProgress={uploadProgress}
        images={images}
      />
      <Dialog open={isDialogOpen && uploadComplete} onOpenChange={setIsDialogOpen}>
        <DialogContent className="sm:max-w-[80%] md:max-w-[70%] lg:max-w-[60%] xl:max-w-[50%] border-none">
          <DialogHeader>
            <DialogTitle className="text-2xl font-bold">
              Poll Created Successfully!
            </DialogTitle>
          </DialogHeader>
          <div className="mb-6">
            <p className="font-semibold text-lg mb-2">Share Link:</p>
            <div className="flex items-center mb-2">
              <Input value={shareLink} readOnly className="flex-grow mr-2" aria-label="Share link" />
              <Button 
                onClick={() => copyToClipboard(shareLink)}
                size="icon"
                aria-label="Copy share link"
                className="group hover:bg-green-500 hover:border-green-500 hover:text-white active:bg-green-600 active:border-green-600 transition-all duration-200"
              >
                <Copy size={20} />
              </Button>
            </div>
            <Button 
              onClick={() => window.open(shareLink, '_blank')}
              className="w-full group bg-primary text-secondary hover:bg-green-500 hover:border-green-500 hover:text-white active:bg-green-600 active:border-green-600 transition-all duration-200"
            >
              <ExternalLink size={20} className="mr-2" /> Go to Vote
            </Button>
          </div>
          <div className="mb-6">
            <p className="font-semibold text-lg mb-2">Results Link:</p>
            <div className="flex items-center mb-2">
              <Input value={adminLink} readOnly className="flex-grow mr-2" aria-label="Results link" />
              <Button 
                onClick={() => copyToClipboard(adminLink)}
                size="icon"
                aria-label="Copy results link"
                className="group hover:bg-green-500 hover:border-green-500 hover:text-white active:bg-green-600 active:border-green-600 transition-all duration-200"
              >
                <Copy size={20} />
              </Button>
            </div>
            <Button 
              onClick={() => window.open(adminLink, '_blank')}
              className="w-full group bg-primary text-secondary hover:bg-green-500 hover:border-green-500 hover:text-white active:bg-green-600 active:border-green-600 transition-all duration-200"
            >
              <ExternalLink size={20} className="mr-2" /> Go to Results
            </Button>
          </div>
          <DialogFooter>
            <Button 
              onClick={() => setIsDialogOpen(false)}
              className="w-full bg-red-600 text-white transition-colors border-0 hover:bg-red-700"
            >
              Close
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Dialog open={showAdminModal} onOpenChange={setShowAdminModal}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Admin Login</DialogTitle>
          </DialogHeader>
          <Input
            type="text"
            placeholder="Username"
            value={adminUsername}
            onChange={(e) => setAdminUsername(e.target.value)}
          />
          <Input
            type="password"
            placeholder="Password"
            value={adminPassword}
            onChange={(e) => setAdminPassword(e.target.value)}
          />
          <DialogFooter>
            <Button onClick={handleAdminLogin}>Login</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Features />
      <Footer />
      <Toaster />
    </>
  );
};

export default PollCreator;