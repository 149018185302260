import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@/components/ThemeProvider';
import '@/styles/globals.css';
import '@/styles/index.css';
import PollCreator from '@/pages/PollCreator';
import AdminPage from '@/pages/AdminPage';
import VotePage from '@/pages/VotePage';
import ErrorBoundary from '@/components/ErrorBoundary';
import { Analytics } from '@vercel/analytics/react';
import FeedbackButton from '@/components/FeedbackButton';
import NightModeToggle from '@/components/NightModeToggle';
import About from '@/pages/About'
import TOS from '@/pages/TOS'
import PP from '@/pages/PP'
import Cookies from '@/pages/Cookies'
import { SpeedInsights } from "@vercel/speed-insights/react"
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN, // Use REACT_APP_ prefix for Create React App
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/picpol\.vercel\.app\/api/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  debug: true, // Enable debug mode
});

console.log("Sentry initialized with DSN:", process.env.REACT_APP_SENTRY_DSN);

const ErrorFallback: Sentry.FallbackRender = ({ error, resetError }) => {
  return (
    <div className="error-fallback">
      <h1>Oops! Something went wrong.</h1>
      <p>Error: {error instanceof Error ? error.message : String(error)}</p>
      <button onClick={resetError}>Try again</button>
      <p>If this error persists, please contact support.</p>
    </div>
  );
};

const App: React.FC = () => {
  return (
    <ThemeProvider>
      <SpeedInsights />
      <Router basename={process.env.PUBLIC_URL}>
        <ErrorBoundary>
          <NightModeToggle />
          <Routes>
            <Route path="/" element={<PollCreator />} />
            <Route path="/vote/:pollId" element={<VotePage />} />
            <Route path="/admin/:pollId/:adminToken" element={<AdminPage />} />
            <Route path="/about" element={<About />} />
            <Route path="/TOS" element={<TOS />} />   
            <Route path="/PP" element={<PP />} />      
            <Route path="/Cookies" element={<Cookies />} />      
          </Routes>
          <FeedbackButton />
        </ErrorBoundary>
        <Analytics />
      </Router>
    </ThemeProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={ErrorFallback}>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);