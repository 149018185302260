import { useState, useCallback, useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { generateSecureToken } from '@/lib/utils';
import config from '@/config/config';
import { useToast } from "@/components/ui/use-toast";
import { PlanFeatures, getFreePlan, getPaidPlan, fetchPlansConfig } from '@/utils/plans';

export interface ImageType {
  id: string;
  file: File;
  preview: string;
  name: string;
  uploadStatus: 'pending' | 'uploading' | 'completed' | 'error';
}

interface UploadResult {
  success: boolean;
  url?: string;
  error?: string;
}

export const usePollCreator = () => {
  const [pollName, setPollName] = useState('');
  const [pollDescription, setPollDescription] = useState('');
  const [images, setImages] = useState<ImageType[]>([]);
  const imagesRef = useRef(images);
  const [scale, setScale] = useState(3);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [shareLink, setShareLink] = useState('');
  const [adminLink, setAdminLink] = useState('');
  const [criteria, setCriteria] = useState<string[]>([]);
  const [customCriterion, setCustomCriterion] = useState('');
  const [showCriteriaWarning, setShowCriteriaWarning] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [userPlan, setUserPlan] = useState<PlanFeatures | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const { toast } = useToast();

  const predefinedCriteria = ['Quality', 'Similarity', 'Price', 'Design', 'Usability'];

  useEffect(() => {
    imagesRef.current = images;
  }, [images]);

  useEffect(() => {
    const loadPlans = async () => {
      try {
        await fetchPlansConfig();
        setUserPlan(getFreePlan()); 
      } catch (error) {
        console.error('Error loading plans:', error);
        toast({
          variant: "destructive",
          title: "Error Loading Plans",
          description: "Failed to load plan information. Please try again.",
        });
      } finally {
        setIsLoading(false);
      }
    };

    loadPlans();
  }, [toast]);

  const toggleAdminMode = async (username: string, password: string) => {
    try {
      console.log('Attempting admin login...');
      const apiUrl = config.useVercelApi ? '/api/login' : `${config.apiBaseUrl}/api/login`;
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();
      console.log('Server response:', data);

      if (data.success) {
        console.log('Admin login successful');
        setUserPlan(getPaidPlan());
        toast({
          title: "Admin mode activated",
          description: "You now have access to all features.",
        });
      } else {
        console.log('Admin login failed');
        setUserPlan(getFreePlan());
        toast({
          variant: "destructive",
          title: "Authentication failed",
          description: "Invalid credentials. Standard limitations apply.",
        });
      }
    } catch (error) {
      console.error('Error during admin login:', error);
      toast({
        variant: "destructive",
        title: "Login Error",
        description: "An error occurred during login. Please try again.",
      });
    }
  };

  const uploadImageWithRetry = async (image: ImageType, retries = 3): Promise<UploadResult> => {
    for (let attempt = 0; attempt < retries; attempt++) {
      try {
        if (userPlan && image.file.size > userPlan.maxFileSize) {
          throw new Error(`File size exceeds the ${userPlan.maxFileSize / (1024 * 1024)}MB limit for your plan.`);
        }

        console.log(`Uploading file: ${image.file.name}, Size: ${image.file.size} bytes`);

        setImages(prevImages =>
          prevImages.map(img =>
            img.id === image.id ? { ...img, uploadStatus: 'uploading' } : img
          )
        );

        const formData = new FormData();
        formData.append('file', image.file);

        const apiUrl = config.useVercelApi ? `/api/upload` : `${config.apiBaseUrl}/api/upload`;
        console.log('API URL:', apiUrl);

        const response = await fetch(apiUrl, {
          method: 'POST',
          body: formData,
        });

        console.log('Response status:', response.status);

        if (!response.ok) {
          const errorText = await response.text();
          console.error('Error response:', errorText);
          throw new Error(errorText || 'Upload failed');
        }

        const blob = await response.json();
        console.log('File upload response:', JSON.stringify(blob, null, 2));

        setImages(prevImages =>
          prevImages.map(img =>
            img.id === image.id ? { ...img, preview: blob.url, uploadStatus: 'completed' } : img
          )
        );

        // Update progress after each successful upload
        setUploadProgress(prev => {
          const newProgress = prev + (100 / images.length);
          return Math.min(newProgress, 100);
        });

        return { success: true, url: blob.url };
      } catch (error: unknown) {
        console.error(`Error uploading image ${image.file.name} (Attempt ${attempt + 1}/${retries}):`, error);

        if (attempt === retries - 1) {
          setImages(prevImages =>
            prevImages.map(img =>
              img.id === image.id ? { ...img, uploadStatus: 'error' } : img
            )
          );

          if (error instanceof TypeError && error.message === 'Failed to fetch') {
            toast({
              variant: "destructive",
              title: "Network Error",
              description: "Unable to connect to the server. Please check your internet connection and try again.",
            });
          } else {
            toast({
              variant: "destructive",
              title: "Upload Error",
              description: `Failed to upload ${image.file.name}. Please try again.`,
            });
          }

          return { success: false, error: error instanceof Error ? error.message : String(error) };
        }

        // Wait before retrying (exponential backoff)
        await new Promise(resolve => setTimeout(resolve, Math.pow(2, attempt) * 1000));
      }
    }

    // This should never be reached due to the return in the last iteration of the loop
    return { success: false, error: "Unexpected error occurred" };
  };

  const uploadImages = async (images: ImageType[], concurrency = 3): Promise<UploadResult[]> => {
    const results: UploadResult[] = [];
    const queue = [...images];
    const inProgress = new Set<string>();

    setUploadProgress(0);  // Reset progress at the start of uploads

    const processImage = async () => {
      if (queue.length === 0 && inProgress.size === 0) {
        return;
      }

      if (queue.length > 0 && inProgress.size < concurrency) {
        const image = queue.shift()!;
        inProgress.add(image.id);

        const result = await uploadImageWithRetry(image);
        results.push(result);

        inProgress.delete(image.id);
        processImage();
      }
    };

    // Start initial batch of uploads
    const initialBatch = Math.min(concurrency, queue.length);
    for (let i = 0; i < initialBatch; i++) {
      processImage();
    }

    // Wait for all uploads to complete
    while (inProgress.size > 0 || queue.length > 0) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }

    setUploadProgress(100);  // Ensure progress is 100% when all uploads are done
    return results;
  };

  const handleImageNameChange = (id: string, name: string) => {
    setImages(prevImages =>
      prevImages.map(img => img.id === id ? { ...img, name } : img)
    );
  };

  const handleImageDelete = useCallback((id: string) => {
    console.log('Deleting image with id:', id);
    console.log('Current images:', imagesRef.current);
    setImages(prevImages => {
      const newImages = prevImages.filter(img => img.id !== id);
      console.log('New images array:', newImages);
      return newImages;
    });
  }, []);

  const toggleCriterion = (criterion: string) => {
    setCriteria(prev => {
      if (prev.includes(criterion)) {
        const newCriteria = prev.filter(c => c !== criterion);
        setShowCriteriaWarning(false);
        return newCriteria;
      } else if (userPlan && prev.length < userPlan.maxVotingCriteria) {
        const newCriteria = [...prev, criterion];
        setShowCriteriaWarning(newCriteria.length === userPlan.maxVotingCriteria);
        return newCriteria;
      }
      return prev;
    });
  };

  const addCustomCriterion = () => {
    if (userPlan && customCriterion && criteria.length < userPlan.maxVotingCriteria) {
      setCriteria(prev => {
        const newCriteria = [...prev, customCriterion];
        setShowCriteriaWarning(newCriteria.length === userPlan.maxVotingCriteria);
        return newCriteria;
      });
      setCustomCriterion('');
    }
  };

  const removeCustomCriterion = (criterionToRemove: string) => {
    setCriteria(prev => {
      const newCriteria = prev.filter(criterion => criterion !== criterionToRemove);
      setShowCriteriaWarning(false);
      return newCriteria;
    });
  };

  const createPoll = async () => {
    if (!pollName.trim()) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Poll name is required",
      });
      return;
    }

    if (criteria.length === 0) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Please select at least one criterion",
      });
      return;
    }

    if (images.length === 0) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Please upload at least one image",
      });
      return;
    }

    const pollId = uuidv4();
    const adminToken = generateSecureToken();
    const creationDate = new Date().toISOString();

    // Calculate expiration date based on userPlan.pollDuration
    const expirationDate = userPlan && userPlan.pollDuration > 0
      ? new Date(Date.now() + userPlan.pollDuration * 60 * 60 * 1000).toISOString()
      : null;

    setIsDialogOpen(true);
    setIsUploading(true);
    setUploadProgress(0);
    setUploadComplete(false);

    console.log('Uploading images...');

    try {
      const uploadResults = await uploadImages(images);
      const successfulUploads = uploadResults.filter(r => r.success);

      if (successfulUploads.length !== images.length) {
        toast({
          variant: "destructive",
          title: "Upload Error",
          description: `Successfully uploaded ${successfulUploads.length} out of ${images.length} images. Please try again for failed uploads.`,
        });
        setIsUploading(false);
        return;
      }

      const pollData = {
        id: pollId,
        adminToken,
        name: pollName,
        description: pollDescription,
        creationDate,
        expirationDate,
        images: successfulUploads.map(({ url }, index) => ({
          id: images[index].id,
          name: images[index].name,
          preview: url,
          votes: Object.fromEntries(criteria.map(criterion => [criterion, 0])),
          feedback: []
        })),
        scale,
        criteria,
        hasWatermark: userPlan?.hasWatermark ?? true, 
        hasBranding: userPlan?.hasBranding ?? true, 
      };

      console.log('Sending poll data to server:', JSON.stringify(pollData, null, 2));

      try {
        const apiUrl = config.useVercelApi ? '/api/polls' : `${config.apiBaseUrl}/api/polls`;
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ pollData }),
        });
  
        if (!response.ok) {
          const errorText = await response.text();
          console.error('Error response:', errorText);
          throw new Error('Failed to create poll');
        }
  
        const { pollId: createdPollId } = await response.json();
        setShareLink(`${window.location.origin}/vote/${createdPollId}`);
        setAdminLink(`${window.location.origin}/admin/${createdPollId}/${adminToken}`);
        setUploadComplete(true);
        setIsUploading(false);
        toast({
          title: "Success",
          description: "Poll created successfully!",
        });
      } catch (error) {
        console.error('Error creating poll:', error);
        setIsUploading(false);
        toast({
          variant: "destructive",
          title: "Poll Creation Error",
          description: "Failed to create poll. Please try again.",
        });
      }
    } catch (error) {
      console.error('Error during image upload:', error);
      setIsUploading(false);
      setUploadProgress(0);  // Reset progress on error
      toast({
        variant: "destructive",
        title: "Upload Error",
        description: "An error occurred during upload. Please try again.",
      });
    }
  };

  const getGridColsClass = useCallback(() => {
    const cols = Math.min(Math.max(Math.floor(9 - scale), 1), 8);
    return `grid-cols-${cols}`;
  }, [scale]);

  return {
    pollName,
    setPollName,
    pollDescription,
    setPollDescription,
    images,
    setImages,
    scale,
    setScale,
    isDialogOpen,
    setIsDialogOpen,
    shareLink,
    adminLink,
    handleImageNameChange,
    handleImageDelete,
    createPoll,
    getGridColsClass,
    criteria,
    setCriteria,
    customCriterion,
    setCustomCriterion,
    toggleCriterion,
    addCustomCriterion,
    removeCustomCriterion,
    predefinedCriteria,
    showCriteriaWarning,
    uploadProgress,
    isUploading,
    uploadComplete,
    userPlan,
    toggleAdminMode,
    isLoading,
  };
};