import React, { useMemo, useState, useEffect, useRef } from 'react';
import { BarChart, PieChart, Pie, Cell, Bar, ResponsiveContainer, Tooltip, LabelList, CartesianGrid } from 'recharts';
import { TrendingUp, Share, Download, Facebook, Twitter, Phone, Instagram, BarChartIcon, PieChartIcon, Link } from "lucide-react";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Popover, PopoverTrigger, PopoverContent } from "@/components/ui/popover";
import ImageGenerator from '@/components/ImageGenerator';
import { Skeleton } from "@/components/ui/skeleton";
import { ChartDataItem, PollData } from '@/types/types';
import { CustomTooltip } from '@/components/CustomTooltip';
import { renderCustomizedLabel } from '@/utils/chartUtils';

type ToastFunction = {
  (props: { variant: "default" | "destructive"; title: string; description: string }): void;
};

interface GraphComponentProps {
    pollData: PollData;
    chartData: ChartDataItem[] | undefined;
    selectedCriterion: string;
    setSelectedCriterion: (criterion: string) => void;
    COLORS: string[];
    adminLink: string;
    copyToClipboard: (text: string) => void;
    shareToFacebook: () => void;
    shareToTwitter: () => void;
    shareToWhatsApp: () => void;
    shareToInstagram: () => void;
    toast: ToastFunction;
}

const GraphComponent: React.FC<GraphComponentProps> = ({
    pollData,
    chartData,
    selectedCriterion,
    setSelectedCriterion,
    COLORS,
    adminLink,
    copyToClipboard,
    shareToFacebook,
    shareToTwitter,
    shareToWhatsApp,
    shareToInstagram,
    toast
}) => {
  const [activeCriterion, setActiveCriterion] = useState(selectedCriterion);
  const [chartType, setChartType] = useState<'bar' | 'pie'>('bar');
  const [resultImageUrl, setResultImageUrl] = useState<string | null>(null);
  const [isGeneratingImage, setIsGeneratingImage] = useState(false);
  const [isSharePopoverOpen, setIsSharePopoverOpen] = useState(false);
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isSharePopoverOpen) {
      setIsGeneratingImage(true);
    }
  }, [isSharePopoverOpen]);

  const handleImageGenerated = (imageUrl: string) => {
    console.log('Image generated successfully');
    setResultImageUrl(imageUrl);
    setIsGeneratingImage(false);
  };

  const handleImageError = () => {
    console.error('Failed to generate image');
    setIsGeneratingImage(false);
    toast({
      variant: "destructive",
      title: "Error",
      description: "Failed to generate share image. Please try again.",
    });
  };

  const chartConfig = useMemo(() => {
    return pollData.criteria.reduce((acc: any, criterion: string, index: number) => {
      acc[criterion] = {
        label: criterion,
        color: COLORS[index % COLORS.length],
      };
      return acc;
    }, {});
  }, [pollData.criteria, COLORS]);

  const handleCriterionChange = (criterion: string) => {
    setActiveCriterion(criterion);
    setSelectedCriterion(criterion);
  };

  const handleChartTypeChange = (type: 'bar' | 'pie') => {
    setChartType(type);
  };

  if (!chartData) return null;

  const total = chartData.reduce((sum, item) => sum + (item[activeCriterion] as number || 0), 0);

  const renderChart = () => {
    switch (chartType) {
      case 'bar':
        return (
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={chartData} margin={{ left: 0, right: 0, top: 30, bottom: 0 }}>
              <CartesianGrid vertical={false} />
              <Bar dataKey={activeCriterion} fill={chartConfig[activeCriterion].color} radius={[4, 4, 0, 0]}>
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
                <LabelList dataKey={activeCriterion} position="top" className="fill-foreground font-bold" fontSize={22} />
              </Bar>
              <Tooltip 
                content={<CustomTooltip activeCriterion={activeCriterion} />} 
                cursor={{ fill: 'rgba(0, 0, 0, 0.1)' }}
              />
            </BarChart>
          </ResponsiveContainer>
        );
      case 'pie':
        return (
          <ResponsiveContainer width="100%" height={400} className="font-bold">
            <PieChart margin={{ top: 40, right: 40, bottom: 40, left: 40 }}>
              <Pie 
                data={chartData} 
                dataKey={activeCriterion} 
                nameKey="name" 
                cx="50%" 
                cy="50%" 
                outerRadius={150}
                label={renderCustomizedLabel}
                labelLine={false}
              >
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip activeCriterion={activeCriterion} />} />
            </PieChart>
          </ResponsiveContainer>
        );
    }
  };

  return (
    <Card className="flex flex-col w-full">
      <CardHeader className="flex flex-col items-stretch space-y-0 border-b p-0 sm:flex-row">
        <div className="flex flex-1 flex-col justify-center gap-1 px-6 py-5 sm:py-6">
          <CardTitle className="text-2xl font-bold">Voting Results</CardTitle>
          <CardDescription>Showing votes for each criterion</CardDescription>
        </div>
        <div className="flex flex-wrap">
          <Popover open={isSharePopoverOpen} onOpenChange={setIsSharePopoverOpen}>
            <PopoverTrigger asChild>
              <button
                className="relative z-30 flex flex-1 flex-col justify-center gap-1 border-t px-6 py-4 text-left even:border-l 
                           hover:bg-[#fbbd23] hover:text-black transition-colors duration-300
                           sm:border-l sm:border-t-0 sm:px-8 sm:py-6"
                aria-label="Share"
              >
                <span className="text-base font-bold flex items-center justify-center">
                  <Share className="h-4 w-4 mr-2" />
                  Share
                </span>
              </button>
            </PopoverTrigger>
            <PopoverContent className="w-80 p-4 space-y-4">
              {isGeneratingImage ? (
                <div className="space-y-4">
                  <Skeleton className="h-40 w-full rounded-md bg-gray-400 animate-pulse" />
                  <p className="text-center text-sm text-gray-500">Generating image, please wait...</p>
                </div>
              ) : resultImageUrl ? (
                <img src={resultImageUrl} alt="Shared content" className="rounded-md" />
              ) : (
                <div className="text-center">Failed to generate image</div>
              )}
              <div className="grid grid-cols-4 gap-4">
                <Button variant="ghost" size="icon" onClick={shareToFacebook}>
                  <Facebook className="w-6 h-6" />
                  <span className="sr-only">Share on Facebook</span>
                </Button>
                <Button variant="ghost" size="icon" onClick={shareToTwitter}>
                  <Twitter className="w-6 h-6" />
                  <span className="sr-only">Share on Twitter</span>
                </Button>
                <Button variant="ghost" size="icon" onClick={shareToWhatsApp}>
                  <Phone className="w-6 h-6" />
                  <span className="sr-only">Share on WhatsApp</span>
                </Button>
                <Button variant="ghost" size="icon" onClick={shareToInstagram}>
                  <Instagram className="w-6 h-6" />
                  <span className="sr-only">Share on Instagram</span>
                </Button>
              </div>
              <Button 
                className="w-full justify-center" 
                onClick={() => copyToClipboard(adminLink)}
              >
                <Link className="w-4 h-4 mr-2" />
                Copy link to this page
              </Button>
              <Button 
                className="w-full justify-center" 
                onClick={() => {
                  if (resultImageUrl) {
                    const link = document.createElement('a');
                    link.href = resultImageUrl;
                    link.download = 'poll_results.png';
                    link.click();
                  }
                }}
                disabled={!resultImageUrl || isGeneratingImage}
              >
                <Download className="w-4 h-4 mr-2" />
                Download
              </Button>
            </PopoverContent>
          </Popover>
          <button
            data-active={chartType === 'bar'}
            className="relative z-30 flex flex-1 flex-col justify-center gap-1 border-t px-6 py-4 text-left even:border-l 
                       data-[active=true]:bg-[#fbbd23] data-[active=true]:text-black
                       hover:bg-[#fbbd23] hover:text-black transition-colors duration-300
                       sm:border-l sm:border-t-0 sm:px-8 sm:py-6"
            onClick={() => handleChartTypeChange('bar')}
            aria-label="Bar Chart"
          >
            <BarChartIcon className="h-6 w-6 mx-auto" />
          </button>
          <button
            data-active={chartType === 'pie'}
            className="relative z-30 flex flex-1 flex-col justify-center gap-1 border-t px-6 py-4 text-left even:border-l 
                       data-[active=true]:bg-[#fbbd23] data-[active=true]:text-black
                       hover:bg-[#fbbd23] hover:text-black transition-colors duration-300
                       sm:border-l sm:border-t-0 sm:px-8 sm:py-6"
            onClick={() => handleChartTypeChange('pie')}
            aria-label="Pie Chart"
          >
            <PieChartIcon className="h-6 w-6 mx-auto" />
          </button>
          {pollData.criteria.map((criterion: string) => (
            <button
              key={criterion}
              data-active={activeCriterion === criterion}
              className="relative z-30 flex flex-1 flex-col justify-center gap-1 border-t px-6 py-4 text-left even:border-l 
                         data-[active=true]:bg-black data-[active=true]:text-white
                         hover:bg-[#fbbd23] hover:text-black transition-colors duration-300
                         sm:border-l sm:border-t-0 sm:px-8 sm:py-6"
              onClick={() => handleCriterionChange(criterion)}
            >
              <span className="text-base font-bold">{criterion}</span>
            </button>
          ))}
        </div>
      </CardHeader>
      <CardContent className="flex-1 pb-0 relative">
        <div ref={chartRef}>
          {renderChart()}
        </div>
      </CardContent>
      <CardFooter className="flex-col gap-2 text-sm pt-6">
        <div className="flex items-center gap-2 font-medium leading-none">
          Total votes: {total}
          <TrendingUp className="h-4 w-4" />
        </div>
        <div className="leading-none text-muted-foreground">
          Showing votes for {activeCriterion}
        </div>
      </CardFooter>
      {isGeneratingImage && (
        <div className="offscreen">
          <ImageGenerator
            pollName={pollData.name}
            selectedCriterion={activeCriterion}
            chartType={chartType}
            chartData={chartData || []}
            COLORS={COLORS}
            onImageGenerated={handleImageGenerated}
            onError={handleImageError}
          />
        </div>
      )}
    </Card>
  );
};

export default GraphComponent;