import React, { useRef, useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from '@/components/ui/accordion';
import { MessageSquare } from 'lucide-react';
import { formatNumber, calculatePercentage } from '@/utils/chartUtils';
import { Image } from '@/types/types'; // Make sure to import the updated Image type

interface AdminImageCardProps {
  image: Image;
  pollData: {
    criteria: string[];
    hasWatermark: boolean;
    hasBranding: boolean;
  };
}

const AdminImageCard: React.FC<AdminImageCardProps> = ({ image, pollData }) => {
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const imageEl = imageRef.current;
    if (imageEl) {
      const handleLoad = () => {
        setImageDimensions({
          width: imageEl.offsetWidth,
          height: imageEl.offsetHeight
        });
        setIsImageLoaded(true);
      };

      if (imageEl.complete) {
        handleLoad();
      } else {
        imageEl.addEventListener('load', handleLoad);
      }

      return () => {
        imageEl.removeEventListener('load', handleLoad);
      };
    }
  }, [image.preview]);

  return (
    <Card className="relative flex flex-col border shadow-md rounded-lg overflow-visible">
      <CardHeader className="p-2">
        <CardTitle className="text-sm truncate">{image.name || `Image ${image.id}`}</CardTitle>
      </CardHeader>
      <CardContent className="p-2 flex flex-col">
        <div className="w-full aspect-square flex items-center justify-center overflow-hidden mb-4 relative">
          <img
            ref={imageRef}
            src={image.preview}
            alt={image.name || `Poll image ${image.id}`}
            className="max-w-full max-h-full w-auto h-auto object-contain"
          />
          {pollData.hasWatermark && isImageLoaded && imageDimensions.width > 0 && imageDimensions.height > 0 && (
            <div
              className="absolute bg-black bg-opacity-50 text-white text-xl px-2 py-1 rounded-tl select-none pointer-events-none font-bold"
              style={{
                right: `calc(50% - ${imageDimensions.width / 2}px)`,
                bottom: `calc(50% - ${imageDimensions.height / 2}px)`,
                transform: 'translate(0, 0)',
              }}
            >
              Picpol
            </div>
          )}
        </div>
        <div className="mb-4">
          {pollData.criteria.map(criterion => {
            const voteCount = image.votes[criterion] || 0;
            const totalVotes = pollData.criteria.reduce((sum, crit) => sum + (image.votes[crit] || 0), 0);
            const percentage = calculatePercentage(voteCount, totalVotes);

            return (
              <div key={criterion} className="flex items-center justify-between mb-1 text-sm">
                <span className="font-medium">{criterion}:</span>
                <span className="bg-foreground text-background px-2 py-1 rounded-full">
                  {formatNumber(voteCount)} ({percentage}%)
                </span>
              </div>
            );
          })}
          <div className="flex items-center justify-between mt-2 text-sm font-bold">
            <span>Total Votes:</span>
            <span className="bg-foreground text-background px-2 py-1 rounded-full">
              {formatNumber(Object.values(image.votes).reduce((a, b) => (a || 0) + (b || 0), 0))}
            </span>
          </div>
        </div>
        {image.feedback && image.feedback.length > 0 && (
          <div className="relative w-full mt-auto">
            <Accordion type="single" collapsible className="w-full">
              <AccordionItem value="feedback" className="border-0">
                <AccordionTrigger className="bg-foreground text-background p-2 flex items-center justify-center transition-all duration-200 rounded-t-lg data-[state=open]:rounded-t-lg data-[state=open]:rounded-b-none rounded-lg">
                  <MessageSquare className="mr-2 h-4 w-4 text-primary" />
                  Feedback ({image.feedback.length})
                </AccordionTrigger>
                <AccordionContent className="absolute top-full left-0 w-full bg-foreground text-background p-2 max-h-60 overflow-y-auto rounded-b-lg z-10">
                  {image.feedback.map((fb, index) => (
                    <p key={index} className="mb-2 text-sm">{fb}</p>
                  ))}
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div>
        )}
      </CardContent>
      {pollData.hasBranding}
    </Card>
  );
};

export default AdminImageCard;