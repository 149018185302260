import React from 'react';
import { useAdminPageLogic } from '../hooks/AdminPageLogic';
import { Alert, AlertDescription } from "@/components/ui/alert";
import Footer from '@/components/layout/Footer';
import GraphComponent from '@/components/GraphComponent';
import { Image } from '@/types/types';
import { useGridStyle } from '@/hooks/useGridStyle';
import { useToast } from "@/components/ui/use-toast";
import { Toaster } from "@/components/ui/toaster";
import CountdownTimer from '@/components/CountdownTimer';
import AdminImageCard from '@/components/AdminImageCard'; // Make sure to import the AdminImageCard

const AdminPage: React.FC = () => {
  const {
    pollData,
    selectedCriterion,
    setSelectedCriterion,
    error,
    chartData,
    COLORS,
    isLoading
  } = useAdminPageLogic();

  const gridStyle = useGridStyle(pollData?.scale ?? 3);
  const { toast } = useToast();

  if (error) {
    return <Alert variant="destructive"><AlertDescription>{error}</AlertDescription></Alert>;
  }

  if (isLoading) {
    return <Alert><AlertDescription>Loading poll data...</AlertDescription></Alert>;
  }

  if (!pollData) {
    return <Alert variant="destructive"><AlertDescription>No poll data available</AlertDescription></Alert>;
  }

  const adminLink = `${window.location.origin}/admin/${pollData.id}/${pollData.adminToken}`;

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      toast({
        title: "Link Copied",
        description: "The admin link has been copied to your clipboard.",
      });
    }, (err) => {
      console.error('Could not copy text: ', err);
      toast({
        title: "Copy Failed",
        description: "Failed to copy the link. Please try again.",
        variant: "destructive",
      });
    });
  };

  const shareToFacebook = () => {
    const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(adminLink)}`;
    window.open(url, '_blank');
  };

  const shareToTwitter = () => {
    const text = `Check out this poll results!`;
    const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(adminLink)}`;
    window.open(url, '_blank');
  };

  const shareToWhatsApp = () => {
    const text = `Check out this poll results: ${adminLink}`;
    const url = `https://wa.me/?text=${encodeURIComponent(text)}`;
    window.open(url, '_blank');
  };

  const shareToInstagram = () => {
    toast({
      title: "Instagram Sharing",
      description: "Direct sharing to Instagram stories is not available. You can copy the link and share it manually.",
    });
  };

  return (
    <>
      <div className="p-4 max-w-6xl mx-auto">
        <h1 className="text-3xl font-bold mb-4">Results of {pollData.name}</h1>
        {pollData.description && <p className="text-muted-foreground mb-4">{pollData.description}</p>}
        <p className="text-sm text-muted-foreground mb-2">Created on: {new Date(pollData.creationDate).toLocaleDateString()}</p>
        {pollData.expirationDate && (
          <div className="mb-6">
            <CountdownTimer expirationDate={pollData.expirationDate} />
          </div>
        )}

        <div className="mb-8">
          <GraphComponent
            pollData={pollData}
            chartData={chartData}
            selectedCriterion={selectedCriterion}
            setSelectedCriterion={setSelectedCriterion}
            COLORS={COLORS}
            adminLink={adminLink}
            copyToClipboard={copyToClipboard}
            shareToFacebook={shareToFacebook}
            shareToTwitter={shareToTwitter}
            shareToWhatsApp={shareToWhatsApp}
            shareToInstagram={shareToInstagram}
            toast={toast}
          />
        </div>

        <div className="grid gap-4" style={gridStyle}>
          {pollData.images.map((image: Image) => (
            <AdminImageCard key={image.id} image={image} pollData={pollData} />
          ))}
        </div>
      </div>
      {pollData.hasBranding}
      <Footer />
      <Toaster />
    </>
  );
};

export default AdminPage;