import React from 'react';
import { ChartDataItem } from '../types/types';

interface CustomTooltipProps {
  active?: boolean;
  payload?: Array<{ payload: ChartDataItem }>;
  label?: string;
  activeCriterion: string;
}

export const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, activeCriterion }) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;
    const criterionValue = data[activeCriterion] as number;
    
    const totalCriterionVotes = payload.reduce((sum, item) => sum + ((item.payload[activeCriterion] as number) || 0), 0);
    
    const percentage = totalCriterionVotes > 0 
      ? ((criterionValue / totalCriterionVotes) * 100).toFixed(2) 
      : "0.00";
    
    const truncatedName = data.name.length > 20 ? data.name.substring(0, 20) + '...' : data.name;

    return (
      <div className="bg-secondary p-2 border rounded shadow-md text-secondary-foreground">
        <img src={data.preview} alt={data.name} className="w-full h-32 object-cover mb-2 rounded" />
        <p className="font-semibold text-sm mb-2">{truncatedName}</p>
        <div className="flex justify-between items-center mb-1 ">
          <span className="text-sm">{activeCriterion} Votes:</span>
          <span className="bg-foreground text-background px-2 py-1 rounded-full text-sm font-bold">
            {criterionValue}
          </span>
        </div>
        <div className="flex justify-between items-center mb-1">
          <span className="text-sm">Total Votes:</span>
          <span className="bg-foreground text-background px-2 py-1 rounded-full text-sm font-bold">
            {totalCriterionVotes}
          </span>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-sm">Percentage:</span>
          <span className="bg-foreground text-background px-1 py-1 rounded-full text-sm font-bold">
            {percentage}%
          </span>
        </div>
      </div>
    );
  }
  return null;
};