import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '@/components/layout/Footer';

const Cookies = () => {
  return (
    <div className="flex flex-col min-h-[100dvh]">
      <header className="px-4 lg:px-6 h-14 flex items-center">
        <Link to="/" className="inline-flex items-center gap-2 hover:underline">
          <ArrowLeftIcon className="h-5 w-5" />
          <span>Return to Home</span>
        </Link>
      </header>
      <Footer />
    </div>
  );
};

const ArrowLeftIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m12 19-7-7 7-7" />
      <path d="M19 12H5" />
    </svg>
  );
};

export default Cookies;