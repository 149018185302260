import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '@/components/layout/Footer';
import '@/styles/globals.css'; // Import your global styles

const About = () => {
  return (
    <div className="flex flex-col min-h-[100dvh]">
      <header className="px-4 lg:px-6 h-14 flex items-center">
        <Link to="/" className="inline-flex items-center gap-2 hover:underline">
          <ArrowLeftIcon className="h-5 w-5" />
          <span>Return to Home</span>
        </Link>
      </header>
      <main className="flex-1">
        <section className="w-full py-12 md:py-24 lg:py-32">
          <div className="container grid gap-6 px-4 md:px-6 lg:grid-cols-2 lg:gap-12">
            <div className="space-y-4">
              <h1 className="text-8xl font-bold mb-4">Picpol</h1>
              <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">No BS, Just Polls</h2>
              <p className="text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                Most poll tools are bloated. We keep it simple. Create polls fast, get insights quick. No extras, no nonsense. Just you, your visuals, and valuable data.
              </p>
            </div>
            <div className="flex items-center justify-center">
              {/* Empty div for spacing */}
            </div>
          </div>
        </section>
        <section className="w-full py-12 md:py-24 lg:py-32 bg-muted">
          <div className="container grid gap-6 px-4 md:px-6 lg:grid-cols-2 lg:gap-12">
            <div className="space-y-4">
              <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">Built by a Frustrated Creator</h2>
              <p className="text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                In 2024, our founder was tired of clunky, flashy poll tools. As a minimalism fan, they wanted something clean and straightforward. So, they built Picpol - the no-frills platform for creators who want to get things done.
              </p>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

const ArrowLeftIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m12 19-7-7 7-7" />
      <path d="M19 12H5" />
    </svg>
  );
};

export default About;
