import React, { useState } from 'react';
import { Sheet, SheetTrigger, SheetContent } from "@/components/ui/sheet"
import { Button } from "@/components/ui/button"
import { Label } from "@/components/ui/label"
import { Textarea } from "@/components/ui/textarea"
import { Input } from "@/components/ui/input"
import { useToast } from "@/components/ui/use-toast"
import { MessageCircle, Star } from 'lucide-react';

export default function FeedbackButton() {
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [valueAddition, setValueAddition] = useState('');
  const [price, setPrice] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const { toast } = useToast();
  const [isOpen, setIsOpen] = useState(false);

  const validateEmail = (email: string) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async () => {
    if (!validateEmail(email)) {
      setEmailError('Please enter a valid email address');
      return;
    }

    setEmailError('');

    const data = {
      rating,
      feedback,
      valueAddition,
      price,
      email
    };

    try {
      const response = await fetch('https://hook.eu1.make.com/61pnn1af1xqllhmfgvs2qhp1nsw2g4yv', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        toast({
          title: "Success",
          description: "Feedback submitted successfully. Thank you!",
        });
        // Reset form fields
        setRating(0);
        setFeedback('');
        setValueAddition('');
        setPrice('');
        setEmail('');
        setIsOpen(false); // Close the sheet on successful submission
      } else {
        throw new Error('Failed to submit feedback');
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
      toast({
        title: "Error",
        description: "Failed to submit feedback. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="relative">
      <Sheet open={isOpen} onOpenChange={setIsOpen}>
        <SheetTrigger asChild>
        <Button className="fixed right-0 top-1/2 -translate-y-1/2 md:top-1/2 md:-translate-y-1/2" >
            <MessageCircle className="h-5 w-5" />
          </Button>
        </SheetTrigger>
        <SheetContent side="right" className="w-full max-w-md">
          <div className="flex flex-col items-start gap-6 p-6">
            <div className="space-y-2">
              <h3 className="text-2xl font-bold">Give us your feedback</h3>
              <p className="text-muted-foreground">Help us improve by sharing your thoughts and experiences.</p>
            </div>
            <div className="grid w-full gap-4">
              <div className="grid gap-2">
                <Label htmlFor="rating">How would you rate your experience?</Label>
                <div className="flex items-center gap-2">
                  {[1, 2, 3, 4, 5].map((num) => (
                    <Button 
                      key={num} 
                      variant={rating >= num ? "default" : "ghost"} 
                      size="icon" 
                      className={`rounded-full p-2 ${rating >= num ? "bg-foreground text-background" : "hover:bg-muted"}`}
                      onClick={() => setRating(num)}
                    >
                      <Star className="h-6 w-6" />
                    </Button>
                  ))}
                </div>
              </div>
              <div className="grid gap-2">
                <Label htmlFor="feedback">Feedback</Label>
                <Textarea
                  id="feedback"
                  placeholder="Share your thoughts and suggestions..."
                  className="min-h-[100px]"
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="valueAddition">What would make this tool more valuable to you?</Label>
                <Input 
                  id="valueAddition" 
                  placeholder="Share your ideas" 
                  value={valueAddition}
                  onChange={(e) => setValueAddition(e.target.value)}
                />
              </div>
              <div className="grid gap-2">
                <Label htmlFor="email">Email</Label>
                <Input 
                  id="email" 
                  type="email" 
                  placeholder="Enter your email address" 
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    if (emailError) setEmailError('');
                  }}
                />
                {emailError && <p className="text-destructive text-sm">{emailError}</p>}
              </div>
            </div>
            <div className="flex w-full justify-end gap-2">
            <Button
  className="close-button"
  onClick={() => setIsOpen(false)}
>
  Cancel
</Button>
              <Button onClick={handleSubmit} className="text-secondary hover:bg-primary hover:text-background active:bg-secondary active:text-primary ">Submit Feedback</Button>
            </div>
          </div>
        </SheetContent>
      </Sheet>
    </div>
  )
}