import React, { useRef, useEffect, useState } from 'react';
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { MessageSquare } from 'lucide-react';
import { getCriterionIcon } from '@/utils/votePageUtils';

interface Image {
  id: string;
  name: string;
  preview: string;
}

interface PollData {
  criteria: string[];
  hasWatermark: boolean;
  hasBranding: boolean;
}

interface UserVotes {
  [key: string]: {
    [key: string]: boolean;
  };
}

interface OpenPopovers {
  [key: string]: boolean;
}

interface FeedbackText {
  [key: string]: string;
}

interface ImageCardProps {
  image: Image;
  pollData: PollData;
  userVotes: UserVotes;
  handleVote: (imageId: string, criterion: string) => void;
  handleFeedbackChange: (imageId: string, feedback: string) => void;
  submitFeedback: (imageId: string) => void;
  openPopovers: OpenPopovers;
  setOpenPopovers: React.Dispatch<React.SetStateAction<OpenPopovers>>;
  feedbackText: FeedbackText;
}

const ImageCard: React.FC<ImageCardProps> = ({
  image,
  pollData,
  userVotes,
  handleVote,
  handleFeedbackChange,
  submitFeedback,
  openPopovers,
  setOpenPopovers,
  feedbackText,
}) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const updateImageDimensions = () => {
      if (imageRef.current) {
        const { offsetWidth, offsetHeight } = imageRef.current;
        setImageDimensions({ width: offsetWidth, height: offsetHeight });
      }
    };

    const imageEl = imageRef.current;
    if (imageEl) {
      if (imageEl.complete) {
        updateImageDimensions();
      } else {
        imageEl.onload = updateImageDimensions;
      }
    }

    window.addEventListener('resize', updateImageDimensions);
    return () => {
      window.removeEventListener('resize', updateImageDimensions);
      if (imageEl) {
        imageEl.onload = null;
      }
    };
  }, [image.preview]);

  return (
    <Card key={image.id} className="overflow-visible flex flex-col justify-between border shadow-md rounded-lg" ref={cardRef}>
      <CardHeader className="p-2">
        <CardTitle className="text-sm">{image.name || `Image ${image.id}`}</CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col justify-center items-center p-2">
        <div className="w-full aspect-square flex items-center justify-center overflow-hidden mb-4 relative">
          <img 
            ref={imageRef}
            src={image.preview} 
            alt={image.name || `Poll image ${image.id}`} 
            className="max-w-full max-h-full w-auto h-auto object-contain"
          />
          {pollData.hasWatermark && (
            <div 
              className="absolute bg-black bg-opacity-50 text-white text-xl px-2 py-1 rounded-tl select-none pointer-events-none font-bold"
              style={{
                right: `calc(50% - ${imageDimensions.width / 2}px)`,
                bottom: `calc(50% - ${imageDimensions.height / 2}px)`,
                transform: 'translate(0, 0)',
              }}
            >
              Picpol
            </div>
          )}
        </div>
        <div className="mt-auto w-full">
          <div className="flex flex-wrap justify-between w-full mb-2">
            {pollData.criteria.map((criterion: string) => {
              const Icon = getCriterionIcon(criterion);
              return (
                <Button 
                  key={criterion}
                  onClick={() => handleVote(image.id, criterion)}
                  variant={userVotes[image.id]?.[criterion] ? "default" : "secondary"}
                  className="flex items-center mb-2 w-[48%]"
                >
                  <Icon className="h-4 w-4 mr-2" />
                  <span>{criterion}</span>
                </Button>
              );
            })}
          </div>
          <Popover 
            open={openPopovers[image.id]} 
            onOpenChange={(open) => setOpenPopovers(prev => ({ ...prev, [image.id]: open }))}
          >
            <PopoverTrigger asChild>
              <Button
                className="w-full py-2 px-4"
                variant="secondary"
              >
                <MessageSquare className="mr-2 h-4 w-4" />
                Send Feedback
              </Button>
            </PopoverTrigger>
            <PopoverContent 
              className="p-0 rounded-lg shadow-md"
              align="start"
              side="bottom"
              sideOffset={5}
              style={{
                width: cardRef.current ? `${cardRef.current.offsetWidth}px` : '100%',
                left: cardRef.current ? `${cardRef.current.offsetLeft}px` : '0',
                transform: 'translateX(-0.5rem)',
              }}
            >
              <div className="p-4">
                <h4 className="font-medium leading-none mb-2">Feedback</h4>
                <Textarea 
                  placeholder="Enter your feedback here..."
                  value={feedbackText[image.id] || ''}
                  onChange={(e) => handleFeedbackChange(image.id, e.target.value)}
                  className="mb-2"
                  rows={3}
                />
                <Button 
                  onClick={() => submitFeedback(image.id)} 
                  className="w-full"
                  disabled={!feedbackText[image.id]?.trim()}
                >
                  Submit Feedback
                </Button>
              </div>
            </PopoverContent>
          </Popover>
        </div>
      </CardContent>
      {pollData.hasBranding}
    </Card>
  );
};

export default ImageCard;