import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import config from '../config/config';

interface Image {
  id: string;
  name: string;
  preview: string;
  votes: Record<string, number | null>;
  feedback: string[];
}

export interface PollData {
  id: string;
  adminToken: string;
  name: string;
  description: string;
  creationDate: string;
  expirationDate: string;
  images: Image[];
  scale: number;
  criteria: string[];
  hasWatermark: boolean;
  hasBranding: boolean;
}

export interface ChartDataItem {
  name: string;
  preview: string;
  total: number;
  [key: string]: number | string | null;
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8', '#82ca9d'];

export const useAdminPageLogic = () => {
  const { pollId, adminToken } = useParams<{ pollId: string; adminToken: string }>();
  const [pollData, setPollData] = useState<PollData | null>(null);
  const [chartType, setChartType] = useState<'pie' | 'bar'>('pie');
  const [selectedCriterion, setSelectedCriterion] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [remainingTime, setRemainingTime] = useState<number | null>(null);

  useEffect(() => {
    const loadPollData = async () => {
      if (!pollId) {
        setError('No poll ID provided');
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        const apiUrl = config.useVercelApi 
          ? `/api/polls/${pollId}`
          : `${config.apiBaseUrl}/api/polls/${pollId}`;

        console.log('Fetching poll data from:', apiUrl);

        const response = await fetch(apiUrl);
        console.log('Response status:', response.status);
        
        if (!response.ok) {
          if (response.status === 404) {
            throw new Error('Poll not found');
          }
          if (response.status === 410) {
            throw new Error('This poll has expired and is no longer available');
          }
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data: PollData = await response.json();
        console.log('Poll data:', data);

        if (!data || typeof data !== 'object' || !Array.isArray(data.images)) {
          throw new Error('Invalid data structure received from server');
        }

        // Verify admin token
        if (data.adminToken !== adminToken) {
          throw new Error('Invalid admin token');
        }

        // Ensure hasWatermark and hasBranding are set, defaulting to true if not present
        const pollDataWithDefaults: PollData = {
          ...data,
          hasWatermark: data.hasWatermark ?? true,
          hasBranding: data.hasBranding ?? true,
        };

        setPollData(pollDataWithDefaults);
        if (pollDataWithDefaults.criteria && pollDataWithDefaults.criteria.length > 0) {
          setSelectedCriterion(pollDataWithDefaults.criteria[0]);
        }

        // Calculate remaining time
        if (pollDataWithDefaults.expirationDate) {
          const now = new Date();
          const expirationDate = new Date(pollDataWithDefaults.expirationDate);
          const timeLeft = expirationDate.getTime() - now.getTime();
          setRemainingTime(Math.max(0, Math.floor(timeLeft / 1000)));
        } else {
          setRemainingTime(null);
        }
      } catch (error) {
        console.error('Error loading poll data:', error);
        setError('Failed to load poll data. ' + (error instanceof Error ? error.message : String(error)));
      } finally {
        setIsLoading(false);
      }
    };

    loadPollData();
  }, [pollId, adminToken]);

  useEffect(() => {
    let timer: number;
    if (remainingTime !== null && remainingTime > 0) {
      timer = window.setInterval(() => {
        setRemainingTime(prevTime => prevTime !== null ? Math.max(0, prevTime - 1) : null);
      }, 1000);
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [remainingTime]);

  const chartData: ChartDataItem[] | undefined = pollData?.images.map(image => {
    const item: ChartDataItem = {
      name: image.name || `Image ${image.id}`,
      preview: image.preview,
      total: Object.values(image.votes).reduce((a: number, b: number | null) => a + (b ?? 0), 0),
    };
    // Add vote data to the item
    Object.entries(image.votes).forEach(([key, value]) => {
      item[key] = value;
    });
    return item;
  }).filter(data => {
    const criterionValue = data[selectedCriterion];
    return typeof criterionValue === 'number' && criterionValue > 0;
  }).sort((a, b) => {
    const aValue = a[selectedCriterion];
    const bValue = b[selectedCriterion];
    return (typeof bValue === 'number' && typeof aValue === 'number') ? bValue - aValue : 0;
  });



  const updatePollData = async (updatedData: Partial<PollData>) => {
    if (!pollData) return;

    try {
      const apiUrl = config.useVercelApi 
        ? `/api/polls/${pollId}`
        : `${config.apiBaseUrl}/api/polls/${pollId}`;

      console.log('Updating poll data at:', apiUrl);

      const response = await fetch(apiUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ updatedPollData: { ...pollData, ...updatedData } }),
      });

      console.log('Update response status:', response.status);

      if (!response.ok) {
        throw new Error('Failed to update poll data');
      }

      const result = await response.json();
      console.log('Update result:', result);

      setPollData(result.pollData);
    } catch (error) {
      console.error('Error updating poll data:', error);
      setError('Failed to update poll data. ' + (error instanceof Error ? error.message : String(error)));
    }
  };

  return {
    pollData,
    chartType,
    setChartType,
    selectedCriterion,
    setSelectedCriterion,
    error,
    chartData,
    COLORS,
    updatePollData,
    isLoading,
    remainingTime
  };
};