import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "@/lib/utils"

const buttonVariants = cva(
  "inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: [
          "bg-primary !text-primary-foreground",
          "border border-border",
          "hover:!text-secondary",
          "active:!text-primary-foreground",
          "active:!bg-secondary"
        ],
        destructive: [
          "bg-destructive !text-destructive-foreground",
          "border border-destructive",
          "hover:bg-[hsl(var(--destructive-darker))] hover:!text-destructive-foreground",
          "active:!text-destructive-foreground"
        ],
        outline: [
          "border border-input bg-background",
          "!text-foreground",
          "hover:bg-accent hover:!text-accent-foreground"
        ],
        secondary: [
          "!text-primary",
          "border border-primary",
          "hover:bg-primary hover:!text-background",
          "active:!text-secondary-foreground"
        ],
        ghost: [
          "!text-muted-foreground",
          "bg-accent",
          "hover:bg-accent hover:!text-secondary",
          "border border-primary"
        ],
        link: [
          "!text-primary underline-offset-4",
          "hover:underline"
        ],
      },
      size: {
        default: "h-9 px-4 py-2",
        sm: "h-8 rounded-md px-3 text-xs",
        lg: "h-10 rounded-md px-8",
        icon: "h-9 w-9",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    )
  }
)
Button.displayName = "Button"

export { Button, buttonVariants }