import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Progress } from "@/components/ui/progress";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";

interface ImageType {
  id: string;
  name: string;
  preview: string;
  uploadStatus: 'pending' | 'uploading' | 'completed' | 'error';
}

interface UploadModalProps {
  isOpen: boolean;
  onClose: () => void;
  uploadProgress: number;
  images: ImageType[];
}

const UploadModal: React.FC<UploadModalProps> = ({ isOpen, onClose, uploadProgress, images }) => {
  const completedUploads = images.filter(img => img.uploadStatus === 'completed').length;
  const totalUploads = images.length;
  const progressPercentage = Math.round((completedUploads / totalUploads) * 100);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[80%] md:max-w-[70%] lg:max-w-[60%] xl:max-w-[50%] border-none">
        <DialogHeader>
          <DialogTitle className="text-2xl font-bold">
            Uploading Files {progressPercentage}%
          </DialogTitle>
        </DialogHeader>
        <div className="mt-6">
          <div className="w-full h-3 rounded-full overflow-hidden bg-muted">
            <Progress 
              value={progressPercentage} 
              className="h-full bg-secondary"
            />
          </div>
          <div className="space-y-4 max-h-[50vh] overflow-y-auto pr-2 mt-6">
            {images.map((image, index) => (
              <Card key={image.id} className="p-4 bg-background">
                <div className="flex items-center space-x-4">
                  <img 
                    src={image.preview} 
                    alt={`Thumbnail ${index + 1}`} 
                    className="w-16 h-16 rounded-md object-cover"
                  />
                  <div className="flex-1 min-w-0">
                    <p className="text-base font-medium text-foreground truncate">
                      {image.name || `Uploaded ${index + 1}`}
                    </p>
                    <p className="text-sm text-muted-foreground">
                      {image.uploadStatus === 'pending' && 'Waiting...'}
                      {image.uploadStatus === 'uploading' && 'Uploading...'}
                      {image.uploadStatus === 'completed' && 'Done'}
                      {image.uploadStatus === 'error' && 'Error'}
                    </p>
                  </div>
                </div>
              </Card>
            ))}
          </div>
        </div>
        <Button 
          className="w-full mt-6 bg-primary text-primary-foreground hover:bg-primary/90 transition-colors"
          disabled={progressPercentage < 100}
          onClick={onClose}
        >
          {progressPercentage < 100 ? 'Uploading...' : 'Close'}
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default UploadModal;