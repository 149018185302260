import React, { useCallback, useState, useEffect, useRef } from 'react';
import { X } from 'lucide-react';
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Slider } from "@/components/ui/slider";
import { Card, CardContent } from "@/components/ui/card";
import { ImageType } from '@/hooks/PollCreatorLogic';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, rectSortingStrategy } from '@dnd-kit/sortable';
import { SortableItem } from '@/components/SortableItem';
import { Header } from './layout/Header';
import CriteriaSelection from './CriteriaSelection';
import ImageUpload from './ImageUpload';
import { useGridStyle } from '@/hooks/useGridStyle';
import { PlanFeatures } from '@/utils/plans';
import { useToast } from "@/components/ui/use-toast";
import { Alert, AlertDescription } from "@/components/ui/alert";

interface CreatePollSectionProps {
  pollName: string;
  setPollName: React.Dispatch<React.SetStateAction<string>>;
  pollDescription: string;
  setPollDescription: React.Dispatch<React.SetStateAction<string>>;
  images: ImageType[];
  setImages: React.Dispatch<React.SetStateAction<ImageType[]>>;
  scale: number;
  setScale: React.Dispatch<React.SetStateAction<number>>;
  criteria: string[];
  setCriteria: React.Dispatch<React.SetStateAction<string[]>>;
  customCriterion: string;
  setCustomCriterion: React.Dispatch<React.SetStateAction<string>>;
  toggleCriterion: (criterion: string) => void;
  addCustomCriterion: () => void;
  removeCustomCriterion: (criterion: string) => void;
  predefinedCriteria: string[];
  showCriteriaWarning: boolean;
  handleImageNameChange: (id: string, name: string) => void;
  handleCreatePoll: () => void;
  userPlan: PlanFeatures;
}

const CreatePollSection: React.FC<CreatePollSectionProps> = ({
  pollName,
  setPollName,
  pollDescription,
  setPollDescription,
  images,
  setImages,
  scale,
  setScale,
  criteria,
  setCriteria,
  customCriterion,
  setCustomCriterion,
  toggleCriterion,
  addCustomCriterion,
  removeCustomCriterion,
  predefinedCriteria,
  showCriteriaWarning,
  handleImageNameChange,
  handleCreatePoll,
  userPlan,
}) => {
  const [deletingImageId, setDeletingImageId] = useState<string | null>(null);
  const gridStyle = useGridStyle(scale);
  const { toast } = useToast();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    console.log('Dropped files:', acceptedFiles);
    const newImages = acceptedFiles.map(file => ({
      id: Math.random().toString(36).substr(2, 9),
      file,
      name: file.name,
      preview: URL.createObjectURL(file),
      uploadStatus: 'pending' as const
    }));

    setImages(prevImages => {
      const existingIds = new Set(prevImages.map(img => img.id));
      const uniqueNewImages = newImages.filter(img => !existingIds.has(img.id));
      const totalImages = prevImages.length + uniqueNewImages.length;
      
      if (totalImages > userPlan.maxImagesPerPoll) {
        const allowedNewImages = uniqueNewImages.slice(0, userPlan.maxImagesPerPoll - prevImages.length);
        toast({
          title: "Image limit reached",
          description: `Your plan allows a maximum of ${userPlan.maxImagesPerPoll} images. Some images were not added.`,
          variant: "destructive",
        });
        return [...prevImages, ...allowedNewImages];
      }
      
      return [...prevImages, ...uniqueNewImages];
    });
  }, [setImages, userPlan.maxImagesPerPoll, toast]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: any) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setImages((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const handleImageDelete = useCallback((id: string) => {
    console.log('Deleting image with id:', id);
    setDeletingImageId(id);
    setTimeout(() => {
      setImages(prevImages => prevImages.filter(img => img.id !== id));
      setDeletingImageId(null);
    }, 300); // This should match the CSS transition duration
  }, [setImages]);

  const handleDeleteClick = useCallback((e: React.MouseEvent, id: string) => {
    e.preventDefault();
    e.stopPropagation();
    handleImageDelete(id);
  }, [handleImageDelete]);

  const getPollDurationMessage = () => {
    if (userPlan.pollDuration > 0) {
      const days = userPlan.pollDuration / 24; // Convert hours to days
      return `This poll will be available for ${days} day${days > 1 ? 's' : ''} from creation.`;
    }
    return null;
  };

  const ImageWithDimensions = ({ image }: { image: ImageType }) => {
    const imageRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
      const updateImageDimensions = () => {
        if (imageRef.current) {
          // No need to store dimensions, just let the browser handle the scaling
        }
      };

      const imageEl = imageRef.current;
      if (imageEl) {
        if (imageEl.complete) {
          updateImageDimensions();
        } else {
          imageEl.onload = updateImageDimensions;
        }
      }

      window.addEventListener('resize', updateImageDimensions);
      return () => {
        window.removeEventListener('resize', updateImageDimensions);
        if (imageEl) {
          imageEl.onload = null;
        }
      };
    }, [image.preview]);

    return (
      <div className="w-full aspect-square flex items-center justify-center overflow-hidden mb-4 relative">
        <img
          ref={imageRef}
          src={image.preview}
          alt={image.name || `Poll image ${image.id}`}
          className="max-w-full max-h-full w-auto h-auto object-contain"
        />
        <button
          className="absolute top-2 right-2 p-1 x-close-button rounded-sm"
          onMouseDown={(e) => handleDeleteClick(e, image.id)}
          aria-label={`Delete ${image.name}`}
        >
          <X size={16} />
        </button>
      </div>
    );
  };

  return (
    <div className="p-4 mx-auto md:container md:mx-auto">
      <h1 className="text-6xl font-bold text-center mb-4">Picpol</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-[7%]">
        <div className="space-y-2">
          <h2 className="text-4xl font-bold">Create a Poll</h2>
          <Input
            type="text"
            placeholder="Enter poll name (required)"
            value={pollName}
            onChange={(e) => setPollName(e.target.value)}
          />
          <Textarea
            placeholder="Enter poll description (optional)"
            value={pollDescription}
            onChange={(e) => setPollDescription(e.target.value)}
          />
          <CriteriaSelection
            criteria={criteria}
            setCriteria={setCriteria}
            customCriterion={customCriterion}
            setCustomCriterion={setCustomCriterion}
            toggleCriterion={toggleCriterion}
            addCustomCriterion={addCustomCriterion}
            removeCustomCriterion={removeCustomCriterion}
            predefinedCriteria={predefinedCriteria}
            showCriteriaWarning={showCriteriaWarning}
            userPlan={userPlan}
          />
          {getPollDurationMessage() && (
            <Alert className="mt-2">
              <AlertDescription>
                {getPollDurationMessage()}
              </AlertDescription>
            </Alert>
          )}
        </div>

        <div>
          <Header />
        </div>
      </div>
      <ImageUpload 
        onDrop={onDrop} 
        userPlan={userPlan}
        currentImageCount={images.length}
      />
      {images.length > 0 && (
        <div className="mt-4">
          <div className="flex items-center justify-center mb-4">
            <span className="text-2xl font-bold mr-2">-</span>
            <Slider
              value={[scale]}
              onValueChange={(value) => setScale(value[0])}
              min={1}
              max={6}
              step={1}
              className="w-64 mx-2"
            />
            <span className="text-2xl font-bold ml-2">+</span>
          </div>
          <Button 
            onClick={handleCreatePoll} 
            className="w-full group text-secondary hover:bg-foreground hover:text-background active:bg-secondary transition-all duration-200 mb-4"
          >
            Create Poll
          </Button>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={images}
              strategy={rectSortingStrategy}
            >
              <div className="grid gap-3 mb-2 poll-creator-grid" style={gridStyle}>
                {images.map((image) => (
                  <SortableItem key={image.id} id={image.id}>
                    <Card 
                      className={`overflow-hidden transition-opacity duration-300 ${
                        deletingImageId === image.id ? 'opacity-0' : 'opacity-100'
                      }`}
                    >
                      <CardContent className="p-2 h-full flex flex-col">
                        <ImageWithDimensions image={image} />
                        <Input
                          type="text"
                          placeholder="Image name (optional)"
                          value={image.name}
                          onChange={(e) => handleImageNameChange(image.id, e.target.value)}
                          className="w-full text-sm"
                          aria-label={`Name for ${image.name}`}
                        />
                      </CardContent>
                    </Card>
                  </SortableItem>
                ))}
              </div>
            </SortableContext>
          </DndContext>
          <Button 
            onClick={handleCreatePoll} 
            className="w-full group text-secondary hover:bg-foreground hover:text-background active:bg-secondary transition-all duration-200 mb-4"
          >
            Create Poll
          </Button>
        </div>
      )}
    </div>
  );
};

export default CreatePollSection;