import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useToast } from "@/components/ui/use-toast";
import config from '@/config/config';

interface PollData {
  id: string;
  adminToken: string;
  name: string;
  description: string;
  creationDate: string;
  expirationDate: string;
  images: Image[];
  scale: number;
  criteria: string[];
  hasWatermark: boolean;
  hasBranding: boolean;
}

interface Image {
  id: string;
  name: string;
  preview: string;
  votes: Record<string, number>;
  feedback: string[];
}

interface VotePageLogicReturn {
  pollData: PollData | null;
  userVotes: Record<string, Record<string, boolean>>;
  feedbackText: Record<string, string>;
  error: string | null;
  isVoteDialogOpen: boolean;
  adminLink: string;
  handleVote: (id: string, criterion: string) => void;
  handleFeedbackChange: (id: string, value: string) => void;
  submitFeedback: (id: string) => Promise<void>;
  submitAllVotes: () => Promise<void>;
  closeVoteDialog: () => void;
  openPopovers: Record<string, boolean>;
  setOpenPopovers: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  isLoading: boolean;
  remainingTime: number | null;
}

const useVotePageLogic = (): VotePageLogicReturn => {
  const { pollId } = useParams<{ pollId: string }>();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [pollData, setPollData] = useState<PollData | null>(null);
  const [userVotes, setUserVotes] = useState<Record<string, Record<string, boolean>>>({});
  const [feedbackText, setFeedbackText] = useState<Record<string, string>>({});
  const [error, setError] = useState<string | null>(null);
  const [isVoteDialogOpen, setIsVoteDialogOpen] = useState(false);
  const [adminLink, setAdminLink] = useState('');
  const [openPopovers, setOpenPopovers] = useState<Record<string, boolean>>({});
  const [isLoading, setIsLoading] = useState(true);
  const [remainingTime, setRemainingTime] = useState<number | null>(null);

  useEffect(() => {
    const loadPollData = async () => {
      if (!pollId) {
        setError('No poll ID provided');
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        const apiUrl = config.useVercelApi 
          ? `/api/polls/${pollId}`
          : `${config.apiBaseUrl}/api/polls/${pollId}`;

        console.log('Fetching poll data from:', apiUrl);

        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        console.log('Response status:', response.status);

        if (response.status === 410) {
          setError('This poll has expired and is no longer available.');
          setIsLoading(false);
          return;
        }

        if (!response.ok) {
          const errorText = await response.text();
          console.error('Error response:', errorText);
          throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
        }

        const data = await response.json();
        console.log('Poll data:', data);

        if (!data || typeof data !== 'object' || !Array.isArray(data.images)) {
          throw new Error('Invalid data structure received from server');
        }

        // Ensure hasWatermark and hasBranding are set, defaulting to true if not present
        const pollDataWithDefaults: PollData = {
          ...data,
          hasWatermark: data.hasWatermark ?? true,
          hasBranding: data.hasBranding ?? true,
        };

        setPollData(pollDataWithDefaults);
        if (pollDataWithDefaults.expirationDate) {
          updateRemainingTime(new Date(pollDataWithDefaults.expirationDate));
        } else {
          setRemainingTime(null); // Indicate an indefinite poll
        }
      } catch (error) {
        console.error('Error loading poll data:', error);
        setError(
          'Failed to load poll data. ' +
            (error instanceof Error ? error.message : String(error))
        );
        toast({
          title: 'Error Loading Poll',
          description: 'There was an error loading the poll. Please try again later.',
          variant: 'destructive',
        });
      } finally {
        setIsLoading(false);
      }
    };

    loadPollData();
  }, [pollId, toast]);

  useEffect(() => {
    let timer: number;
    if (pollData && pollData.expirationDate) {
      timer = window.setInterval(() => {
        updateRemainingTime(new Date(pollData.expirationDate));
      }, 1000);
    }
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [pollData]);

  const updateRemainingTime = (expirationDate: Date) => {
    const now = new Date();
    const timeLeft = expirationDate.getTime() - now.getTime();
    if (timeLeft <= 0) {
      setRemainingTime(0);
      setError('This poll has expired and is no longer available.');
    } else {
      setRemainingTime(Math.floor(timeLeft / 1000));
    }
  };

  const handleVote = (id: string, criterion: string) => {
    setUserVotes((prevVotes) => {
      const newVotes = {
        ...prevVotes,
        [id]: {
          ...prevVotes[id],
          [criterion]: !prevVotes[id]?.[criterion],
        },
      };
      console.log('Updated userVotes:', newVotes);
      return newVotes;
    });
  };

  const handleFeedbackChange = (id: string, value: string) => {
    setFeedbackText((prev) => ({ ...prev, [id]: value }));
  };

  const isSpam = (text: string) => {
    return (
      text.length > 1000 || text.split(' ').some((word) => word.length > 30)
    );
  };

  const submitFeedback = async (id: string) => {
    if (!pollData) return;

    const feedback = feedbackText[id]?.trim();
    if (feedback && !isSpam(feedback)) {
      try {
        const updatedImages = pollData.images.map((img) =>
          img.id === id ? { ...img, feedback: [...img.feedback, feedback] } : img
        );
        const updatedPollData = { ...pollData, images: updatedImages };

        const apiUrl = config.useVercelApi
          ? `/api/polls/${pollData.id}`
          : `${config.apiBaseUrl}/api/polls/${pollData.id}`;

        console.log('Submitting feedback to:', apiUrl);

        const response = await fetch(apiUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ updatedPollData }),
        });

        console.log('Feedback submission response status:', response.status);

        if (!response.ok) {
          const errorData = await response.json();
          console.error('Error response from server:', errorData);
          throw new Error(errorData.error || 'Failed to update poll data');
        }

        const result = await response.json();
        console.log('Poll updated successfully:', result);

        setPollData(result.pollData);
        setFeedbackText((prev) => ({ ...prev, [id]: '' }));
        setOpenPopovers((prev) => ({ ...prev, [id]: false }));

        toast({
          title: 'Feedback Submitted',
          description: `Your feedback has been saved successfully.`,
          variant: 'default',
        });
      } catch (err) {
        console.error('Error updating poll data with feedback:', err);
        toast({
          title: 'Error Submitting Feedback',
          description:
            'An error occurred while saving your feedback. Please try again.',
          variant: 'destructive',
        });
      }
    } else {
      toast({
        title: 'Invalid Feedback',
        description: 'Please provide valid feedback and try again.',
        variant: 'destructive',
      });
    }
  };

  const canVote = () => {
    const lastVoteTime = localStorage.getItem(`lastVote_${pollId}`);
    if (lastVoteTime && Date.now() - parseInt(lastVoteTime) < 60000) {
      return false; // Less than 1 minute since last vote
    }
    return true;
  };

  const submitAllVotes = async () => {
    if (!pollData) {
      console.error('Poll data is not available');
      return;
    }

    if (!canVote()) {
      toast({
        title: 'Voting Limit Reached',
        description: 'Please wait before voting again.',
        variant: 'destructive',
        className: 'custom-toast',
      });
      return;
    }

    const hasVotes = Object.values(userVotes).some(imageVotes => 
      Object.values(imageVotes).some(vote => vote)
    );

    if (!hasVotes) {
      toast({
        title: 'No Votes Selected',
        description: 'Please select at least one vote before submitting.',
        variant: 'destructive',
        className: 'custom-toast',
      });
      return;
    }

    const updatedPollData: PollData = {
      ...pollData,
      images: pollData.images.map((img) => ({
        ...img,
        votes: pollData.criteria.reduce((acc, criterion) => {
          const currentVotes = img.votes[criterion] ?? 0;
          acc[criterion] = currentVotes + (userVotes[img.id]?.[criterion] ? 1 : 0);
          return acc;
        }, {} as Record<string, number>),
      })),
    };

    try {
      const apiUrl = config.useVercelApi
        ? `/api/polls/${pollData.id}`
        : `${config.apiBaseUrl}/api/polls/${pollData.id}`;

      console.log('Submitting votes to:', apiUrl);

      const response = await fetch(apiUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ updatedPollData }),
      });

      console.log('Vote submission response status:', response.status);

      if (!response.ok) {
        throw new Error('Failed to update poll data');
      }

      const result = await response.json();
      console.log('Vote submission result:', result);

      setPollData(result.pollData);
      setUserVotes({});
      setAdminLink(
        `${window.location.origin}/admin/${pollData.id}/${pollData.adminToken}`
      );
      setIsVoteDialogOpen(true);

      // Set the cooldown after successful vote
      localStorage.setItem(`lastVote_${pollId}`, Date.now().toString());

      toast({
        title: 'Votes Submitted',
        description: 'Thank you for voting!',
        variant: 'default',
      });
    } catch (err) {
      console.error('Error updating poll data:', err);
      toast({
        title: 'Error Submitting Votes',
        description:
          'An error occurred while saving your votes. Please try again.',
        variant: 'destructive',
        className: 'custom-toast',
      });
    }
  };

  const closeVoteDialog = () => {
    setIsVoteDialogOpen(false);
    navigate('/');
  };

  return {
    pollData,
    userVotes,
    feedbackText,
    error,
    isVoteDialogOpen,
    adminLink,
    handleVote,
    handleFeedbackChange,
    submitFeedback,
    submitAllVotes,
    closeVoteDialog,
    openPopovers,
    setOpenPopovers,
    isLoading,
    remainingTime,
  };
};

export default useVotePageLogic;