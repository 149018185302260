import axios from 'axios';

export interface PlanFeatures {
  maxFileSize: number;
  maxImagesPerPoll: number;
  maxVotingCriteria: number;
  pollDuration: number;
  hasWatermark: boolean;
  hasBranding: boolean;
}

interface PlansConfig {
  freePlan: PlanFeatures;
  paidPlan: PlanFeatures;
}

let plansConfig: PlansConfig | null = null;

const GIST_URL = process.env.REACT_APP_GIST_URL || 'https://gist.githubusercontent.com/merteraslan/552b84748bc4720da0c277f83a232837/raw/plans.json';

export const fetchPlansConfig = async (): Promise<void> => {
  try {
    const response = await axios.get<PlansConfig>(GIST_URL);
    plansConfig = response.data;
    console.log('Fetched plans configuration:', plansConfig);
  } catch (error) {
    console.error('Failed to fetch plans configuration:', error);
    // Fallback to default values if fetch fails
    plansConfig = {
      freePlan: {
        maxFileSize: 5 * 1024 * 1024,
        maxImagesPerPoll: 10,
        maxVotingCriteria: 2,
        pollDuration: 24 * 7,
        hasWatermark: true,
        hasBranding: true,
      },
      paidPlan: {
        maxFileSize: 10 * 1024 * 1024,
        maxImagesPerPoll: 50,
        maxVotingCriteria: 6,
        pollDuration: 0,
        hasWatermark: false,
        hasBranding: false,
      },
    };
  }
};

export const getFreePlan = (): PlanFeatures => {
  if (!plansConfig) {
    throw new Error('Plans configuration not loaded');
  }
  return plansConfig.freePlan;
};

export const getPaidPlan = (): PlanFeatures => {
  if (!plansConfig) {
    throw new Error('Plans configuration not loaded');
  }
  return plansConfig.paidPlan;
};

export const refreshPlans = async (): Promise<void> => {
  await fetchPlansConfig();
};

export const planFeatures = {
  free: getFreePlan,
  paid: getPaidPlan,
};