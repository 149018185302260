import React from 'react';
import { Send, ExternalLink } from 'lucide-react';
import useVotePageLogic from '@/hooks/VotePageLogic';
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { useToast } from "@/components/ui/use-toast";
import { Toaster } from "@/components/ui/toaster";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog";
import Footer from '@/components/layout/Footer';
import { useGridStyle } from '@/hooks/useGridStyle';
import CountdownTimer from '@/components/CountdownTimer';
import ImageCard from '@/components/VoteImageCard';

const VotePage: React.FC = () => {
  const {
    pollData,
    userVotes,
    feedbackText,
    error,
    isVoteDialogOpen,
    adminLink,
    handleVote,
    handleFeedbackChange,
    submitFeedback,
    submitAllVotes,
    closeVoteDialog,
    openPopovers,
    setOpenPopovers,
    isLoading,
  } = useVotePageLogic();

  const { toast } = useToast();

  const gridStyle = useGridStyle(pollData?.scale ?? 3);

  const renderSkeletonCard = () => (
    <div className="flex flex-col space-y-3">
      <Skeleton className="h-4 w-3/4" />
      <Skeleton className="h-40 w-full" />
      <div className="space-y-2">
        <div className="flex justify-between space-x-2">
          <Skeleton className="h-8 w-[48%]" />
          <Skeleton className="h-8 w-[48%]" />
        </div>
        <div className="flex justify-between space-x-2">
          <Skeleton className="h-8 w-[48%]" />
          <Skeleton className="h-8 w-[48%]" />
        </div>
      </div>
      <Skeleton className="h-10 w-full" />
    </div>
  );

  const handleSubmitAllVotes = () => {
    const hasVotes = Object.values(userVotes).some(imageVotes => 
      Object.values(imageVotes).some(vote => vote)
    );

    if (!hasVotes) {
      toast({
        variant: "destructive",
        title: "Error",
        description: "Please select at least one vote before submitting.",
      });
      return;
    }

    submitAllVotes();
  };

  const isPollActive = pollData && (!pollData.expirationDate || new Date(pollData.expirationDate) > new Date());

  const isSubmitDisabled = isLoading || !isPollActive || Object.keys(userVotes).length === 0;

  if (error) {
    return (
      <Alert variant="destructive">
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    );
  }

  return (
    <>
      <div className="p-4 max-w-6xl mx-auto">
        {isLoading ? (
          <>
            <Skeleton className="h-10 w-3/4 mb-4" />
            <Skeleton className="h-4 w-1/2 mb-4" />
            <Skeleton className="h-4 w-1/4 mb-6" />
          </>
        ) : (
          <>
            <h1 className="text-3xl font-bold mb-4">{pollData?.name}</h1>
            {pollData?.description && (
              <p className="text-muted-foreground mb-4">{pollData.description}</p>
            )}
            <p className="text-sm text-muted-foreground mb-2">
              Created on: {pollData && new Date(pollData.creationDate).toLocaleDateString()}
            </p>
            {pollData?.expirationDate && (
              <div className="mb-6">
                <CountdownTimer expirationDate={pollData.expirationDate} />
              </div>
            )}
          </>
        )}

        <div className="grid gap-4 mb-6 relative" style={gridStyle}>
          {isLoading
            ? Array(6).fill(null).map((_, index) => (
                <React.Fragment key={index}>{renderSkeletonCard()}</React.Fragment>
              ))
            : pollData?.images.map(image => (
                <ImageCard
                  key={image.id}
                  image={image}
                  pollData={{
                    criteria: pollData.criteria,
                    hasWatermark: pollData.hasWatermark,
                    hasBranding: pollData.hasBranding,
                  }}
                  userVotes={userVotes}
                  handleVote={handleVote}
                  handleFeedbackChange={handleFeedbackChange}
                  submitFeedback={submitFeedback}
                  openPopovers={openPopovers}
                  setOpenPopovers={setOpenPopovers}
                  feedbackText={feedbackText}
                />
              ))}
        </div>

        <Button 
          onClick={handleSubmitAllVotes} 
          className="w-full"
          size="lg"
          disabled={isSubmitDisabled}
        >
          <Send className="mr-2 h-4 w-4" /> Submit All Votes
        </Button>

        <Dialog open={isVoteDialogOpen} onOpenChange={closeVoteDialog}>
          <DialogContent className="sm:max-w-[80%] md:max-w-[70%] lg:max-w-[60%] xl:max-w-[50%] border-none">
            <DialogHeader>
              <DialogTitle>Thank You for Voting!</DialogTitle>
            </DialogHeader>
            <div className="py-4 space-y-4">
              <p>Your votes have been successfully recorded.</p>
              <p>You can view the current results of this poll using the button below.</p>
              <p>Don't forget to check back later to see the final results!</p>
              <p className="font-medium italic">Why not create your own poll now?</p>
            </div>
            <DialogFooter className="flex flex-col gap-3">
              <Button 
                onClick={() => window.open(adminLink, '_blank')}
                className="w-full"
                variant="default"
              >
                <ExternalLink className="mr-2 h-4 w-4" /> View Results
              </Button>
              <Button 
                onClick={() => window.open('https://picpol.com', '_blank')}
                className="w-full"
                variant="secondary"
              >
                <ExternalLink className="mr-2 h-4 w-4" /> Create Your Poll
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        <Toaster />
      </div>
      <Footer />
    </>
  );
};

export default VotePage;