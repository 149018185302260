import React, { useState, useEffect } from 'react';
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import type { ButtonProps } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { useToast } from "@/components/ui/use-toast";

type ImageProps = {
  src: string;
  alt?: string;
};

type Props = {
  heading: string;
  description: string;
  button: ButtonProps & { title: string };
  image: ImageProps;
  termsAndConditions: string;
};

export type HeaderProps = React.ComponentPropsWithoutRef<"section"> & Partial<Props>;

export const Header = (props: HeaderProps) => {
  const { heading, description, button, termsAndConditions } = {
    ...HeaderDefaults,
    ...props,
  } as Props;

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [referralSource, setReferralSource] = useState('');
  const [budget, setBudget] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    console.log('Environment:', process.env.NODE_ENV);
    console.log('REACT_APP_MAKE_WEBHOOK_URL:', process.env.REACT_APP_MAKE_WEBHOOK_URL);
  }, []);

  const handleWaitlistSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    const webhookUrl = process.env.REACT_APP_MAKE_WEBHOOK_URL;
    console.log('Webhook URL:', webhookUrl ? 'Set' : 'Not set');

    const data = {
      name,
      email,
      referralSource,
      budget: parseFloat(budget) || 0,
      submissionDate: new Date().toISOString(),
    };

    try {
      if (!webhookUrl) {
        console.error('Webhook URL is not set. This should not happen in production.');
        console.log('Form data:', data);
        throw new Error('Webhook URL is not set');
      }

      const response = await fetch(webhookUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`Failed to submit form: ${response.status} ${response.statusText}`);
      }

      setIsOpen(false);
      toast({
        title: "Success!",
        description: "You've been added to the waitlist. We'll notify you when we launch!",
      });

      // Reset form fields
      setName('');
      setEmail('');
      setReferralSource('');
      setBudget('');
    } catch (error: unknown) {
      console.error('Error submitting form:', error);
      let errorMessage = 'An unknown error occurred';
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      toast({
        title: "Error",
        description: `Failed to join waitlist: ${errorMessage}`,
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <header className="h-full flex flex-col">
      <div className="container h-full">
        <div className="h-full flex">
          <div className="order-1 lg:order-2 h-full flex flex-col mb-0">
            <h2 className="text-4xl font-bold md:mb-6 md:text-5xl lg:text-6xl">{heading}</h2>
            <p className="md:text-md">{description}</p>
            <div className="max-w">
              <div className="mb-2 grid grid-cols-1 items-center gap-x-4 gap-y-3 sm:grid-cols-[1fr_max-content] sm:gap-y-4 md:mt-8">
                <Dialog open={isOpen} onOpenChange={setIsOpen}>
                  <DialogTrigger asChild>
                    <Button
                      {...button}
                      variant="default"                    >
                      {button.title}
                    </Button>
                  </DialogTrigger>
                  <DialogContent className="sm:max-w-[80%] md:max-w-[70%] lg:max-w-[60%] xl:max-w-[50%] bg-background border-foreground">
                    <form onSubmit={handleWaitlistSubmit} className="space-y-4 p-6">
                      <div className="space-y-2">
                        <DialogTitle className="text-2xl font-bold">Join Our Waitlist</DialogTitle>
                        <DialogDescription>
                          Be the first to know when we launch. Fill out the form below to get on the waitlist.
                        </DialogDescription>
                      </div>
                      <div className="grid gap-4">
                        <div className="grid gap-2">
                          <Label htmlFor="name" className="text-foreground">Name</Label>
                          <Input id="name" placeholder="Enter your name" value={name} onChange={(e) => setName(e.target.value)} required className="border-foreground" />
                        </div>
                        <div className="grid gap-2">
                          <Label htmlFor="email" className="text-foreground">Email</Label>
                          <Input id="email" type="email" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} required className="border-foreground" />
                        </div>
                        <div className="grid gap-2">
                          <Label htmlFor="referral" className="text-foreground">How did you hear about us?</Label>
                          <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                              <Button variant="outline" className="w-full justify-between border-foreground bg-background text-foreground hover:bg-foreground hover:text-background">
                                {referralSource || "Select referral source"}
                                <ChevronsUpDownIcon className="h-4 w-4" />
                              </Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent
                              className="w-[var(--radix-dropdown-menu-trigger-width)] border-primary bg-background group-hover:muted" 
                              style={{ width: 'var(--radix-dropdown-menu-trigger-width)' }}
                            >
                              {["Google Search", "Word of mouth", "Instagram Ad", "Facebook Ad", "Reddit", "Other"].map((source) => (
                                <DropdownMenuItem key={source} onSelect={() => setReferralSource(source)}>
                                  {source}
                                </DropdownMenuItem>
                              ))}
                            </DropdownMenuContent>
                          </DropdownMenu>
                        </div>
                        <div className="grid gap-2">
                          <Label htmlFor="budget" className="text-foreground">How much would you pay?</Label>
                          <Input id="budget" type="number" min="0" placeholder="Enter an amount" value={budget} onChange={(e) => setBudget(e.target.value)} className="border-foreground" />
                        </div>
                      </div>
                      <Button type="submit" className="w-full text-secondary hover:bg-foreground hover:text-background active:bg-secondary" disabled={isSubmitting}>
                        {isSubmitting ? 'Joining...' : 'Join Waitlist'}
                      </Button>
                    </form>
                  </DialogContent>
                </Dialog>
              </div>
              <div dangerouslySetInnerHTML={{ __html: termsAndConditions }} />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export const HeaderDefaults: HeaderProps = {
  heading: "Create Image Polls with Ease. Get Feedback Fast.",
  description:
    "Picpol is the no-frills image poll creator for busy creators. Craft engaging polls, share them easily, and gather insights quickly.",
  button: { 
    title: "Join our Waitlist!",
    variant: "default"
   },
  termsAndConditions: `
  <p class='text-xs'>
    By clicking "Join our Waitlist!" you're confirming that you agree with our
    <a href='#' class='underline focus-visible:outline-none'>Terms and Conditions</a>.
  </p>
  `,
};

function ChevronsUpDownIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m7 15 5 5 5-5" />
      <path d="m7 9 5-5 5 5" />
    </svg>
  )
}

Header.displayName = "Header";