import { ThumbsUp, Star, DollarSign, Paintbrush, Smile, Zap, LucideIcon } from 'lucide-react';

export const getCriterionIcon = (criterion: string): LucideIcon => {
  switch (criterion.toLowerCase()) {
    case 'quality':
      return ThumbsUp;
    case 'similarity':
      return Star;
    case 'price':
      return DollarSign;
    case 'design':
      return Paintbrush;
    case 'usability':
      return Smile;
    default:
      return Zap;
  }
};

export const getGridColsClass = (scale: number): string => {
  const columns = [6, 5, 4, 3, 2, 1][scale - 1]; 
  return `grid-cols-1 sm:grid-cols-2 md:grid-cols-${columns}`;
};