import React, { useCallback } from 'react';
import { Upload, AlertCircle } from 'lucide-react';
import { useDropzone } from 'react-dropzone';
import { useToast } from "@/components/ui/use-toast";
import { PlanFeatures } from '@/utils/plans';
import { Alert, AlertDescription } from "@/components/ui/alert";

interface ImageUploadProps {
  onDrop: (acceptedFiles: File[]) => void;
  userPlan: PlanFeatures;
  currentImageCount: number;
}

const ImageUpload: React.FC<ImageUploadProps> = ({ onDrop, userPlan, currentImageCount }) => {
  const { toast } = useToast();

  const handleDrop = useCallback((acceptedFiles: File[]) => {
    const validFiles = acceptedFiles.filter(file => {
      if (file.size > userPlan.maxFileSize) {
        toast({
          variant: "destructive",
          title: "File too large",
          description: `${file.name} exceeds the ${userPlan.maxFileSize / (1024 * 1024)}MB limit for your plan.`,
        });
        return false;
      }
      return true;
    });

    const remainingSlots = userPlan.maxImagesPerPoll - currentImageCount;
    if (validFiles.length > remainingSlots) {
      toast({
        variant: "destructive",
        title: "Image limit exceeded",
        description: `You can only add ${remainingSlots} more image(s) to this poll.`,
      });
      onDrop(validFiles.slice(0, remainingSlots));
    } else {
      onDrop(validFiles);
    }
  }, [onDrop, userPlan, currentImageCount, toast]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    accept: {
      'image/*': ['.png', '.gif', '.jpeg', '.jpg']
    },
    maxSize: userPlan.maxFileSize,
    disabled: currentImageCount >= userPlan.maxImagesPerPoll
  });

  const isLimitReached = currentImageCount >= userPlan.maxImagesPerPoll;

  return (
    <>
      <div
        {...getRootProps()}
        className={`mt-8 flex justify-center items-center w-full h-64 border-dashed border-2 rounded-lg p-4 text-center cursor-pointer transition-all ${
          isDragActive ? 'border-primary bg-primary/10' : 
          isLimitReached ? 'border-destructive/50 bg-destructive/5 cursor-not-allowed' : 
          'border-foreground/40 hover:border-foreground'
        }`}
      >
        <input {...getInputProps()} />
        <div className="text-center">
          <Upload className={`mx-auto h-8 w-8 mb-2 ${isLimitReached ? 'text-destructive/50' : 'text-foreground'}`} />
          <p className={`text-sm font-medium ${isLimitReached ? 'text-destructive/50' : 'text-foreground'}`}>
            {isLimitReached
              ? 'Image limit reached'
              : isDragActive
              ? 'Drop the files here ...'
              : 'Drag \'n\' drop some files here, or click to select files'}
          </p>
          <p className="mt-1 text-xs text-muted-foreground/60">
            (Only *.jpeg, *.jpg, *.png and *.gif images will be accepted, max {userPlan.maxFileSize / (1024 * 1024)}MB)
          </p>
          {!isLimitReached && (
            <p className="mt-1 text-xs text-muted-foreground/60">
              {currentImageCount} / {userPlan.maxImagesPerPoll} images uploaded
            </p>
          )}
        </div>
      </div>
      {isLimitReached && (
        <Alert variant="destructive" className="mt-4">
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>
            You've reached the maximum number of images allowed for now ({userPlan.maxImagesPerPoll}).
            {userPlan.maxImagesPerPoll < 50 && " Please join the waitlist to get notified when we publish our upgrade plans."}
          </AlertDescription>
        </Alert>
      )}
    </>
  );
};

export default ImageUpload;