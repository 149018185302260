"use client";

import { useState } from "react";
import { motion } from "framer-motion";
import { useMediaQuery } from "@relume_io/relume-ui";

// Remove this line if it exists and is unused
// type ImageProps = {
//   src: string;
//   alt?: string;
// };

type Feature = {
  columnText: string;
  verticalText: string;
  horizontalText: string;
  heading: string;
  description: string;
  // image: ImageProps;
};

type FeatureWithState = Feature & {
  isActive: boolean;
  setIsActive: () => void;
};

type Props = {
  tagline: string;
  heading: string;
  description: string;
  features: Feature[];
};

export type FeaturesProps = React.ComponentPropsWithoutRef<"section"> & Partial<Props>;

export const Features = (props: FeaturesProps) => {
  const { tagline, heading, description, features } = {
    ...FeaturesDefaults,
    ...props,
  } as Props;

  const [activeIndex, setActiveIndex] = useState<number | null>(0);

  const handleSetIsActive = (index: number) => {
    setActiveIndex((prevIndex) => {
      if (prevIndex === index && features.filter((_, i) => i === prevIndex).length === 1) {
        return prevIndex;
      }
      return prevIndex === index ? null : index;
    });
  };

  return (
    <section className="px-[5%] py-2 md:py-8 lg:py-5 mt-24">
      <div className="container">
        <div className="mb-8 w-full max-w-lg md:mb-12 lg:mb-12">
          <p className="mb-2 font-semibold md:mb-3">{tagline}</p>
          <h1 className="mb-4 text-4xl font-bold md:mb-5 md:text-5xl lg:text-6xl">{heading}</h1>
          <p className="md:text-md">{description}</p>
        </div>
        <div className="flex w-full flex-col overflow-hidden border-b border-l border-r border-border-primary lg:h-[30vh] lg:flex-row lg:border-r-0 lg:border-t mb-8">
          {features.map((feature, index) => (
            <FeatureCard
              key={index}
              isActive={activeIndex === index}
              setIsActive={() => handleSetIsActive(index)}
              {...feature}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

const FeatureCard = ({ isActive, setIsActive, ...feature }: FeatureWithState) => {
  const isMobile = useMediaQuery("(max-width: 991px)");
  const CardContent = isMobile ? motion.div : "div";
  return (
    <motion.div
      className="flex flex-col justify-start overflow-hidden lg:h-[30vh] lg:min-w-20 lg:flex-row lg:border-r lg:border-border-primary"
      onClick={setIsActive}
      animate={{
        width: isMobile ? "100%" : isActive ? "100%" : "4rem",
      }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
    >
      <div className="relative flex h-14 w-full min-w-full cursor-pointer items-center justify-center border-t border-border-primary py-6 md:h-16 lg:h-[30vh] lg:w-16 lg:min-w-16 lg:flex-col lg:justify-between lg:border-none">
        <p className="absolute left-4 whitespace-nowrap text-lg font-bold md:left-6 md:text-xl lg:relative lg:left-0">
          {feature.columnText}
        </p>
        <h2 className="hidden [writing-mode:vertical-rl] lg:mx-auto lg:block lg:rotate-180 lg:text-xl lg:font-bold">
          {feature.verticalText}
        </h2>
        <p className="text-lg font-bold md:text-xl lg:hidden">{feature.horizontalText}</p>
      </div>
      <CardContent
        className="w-full overflow-hidden lg:h-full lg:w-auto lg:min-w-[100vw] lg:overflow-auto"
        animate={{
          height: isActive ? "auto" : "0px",
        }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
      >
        <div className="flex h-full flex-col px-4 pb-6 pt-3 md:px-6 md:pb-8 md:pt-6 lg:w-[30rem] lg:px-8 lg:pb-10 lg:pt-8">
          <h3 className="mb-3 text-3xl font-bold leading-[1.2] md:mb-4 md:text-4xl lg:text-5xl">
            {feature.heading}
          </h3>
          <p className="text-sm md:text-base">{feature.description}</p>
          {/* <div className="mt-4 h-48 md:mt-6 md:h-60 lg:mt-8">
            <img
              src={feature.image.src}
              alt={feature.image.alt}
              className="h-full w-full object-cover"
            />
          </div> */}
        </div>
      </CardContent>
    </motion.div>
  );
};

export const FeaturesDefaults: FeaturesProps = {
  heading: "Collect Feedback, FAST",
  description: "Whether you're a designer, streamer, or AI artist, Picpol simplifies feedback collection so you can focus on creating.",
  features: [
    {
      columnText: "01",
      verticalText: "Easy Creation",
      horizontalText: "Easy Creation",
      heading: "Effortlessly Create Polls",
      description:
        "Create image polls in seconds with our intuitive interface. No complicated setup, just quick results.",
      // image: {
      //   src: "https://relume-assets.s3.amazonaws.com/placeholder-image-landscape.svg",
      //   alt: "Placeholder image 1",
      // },
    },
    {
      columnText: "02",
      verticalText: "Customizable Criteria",
      horizontalText: "Customizable Criteria",
      heading: "Tailor Your Polls",
      description:
        "Use predefined criteria or add your own. Get the specific feedback you need to improve your work.",
      // image: {
      //   src: "https://relume-assets.s3.amazonaws.com/placeholder-image-landscape.svg",
      //   alt: "Placeholder image 2",
      // },
    },
    {
      columnText: "03",
      verticalText: "Engage Your Audience",
      horizontalText: "Engage Your Audience",
      heading: "Instant Sharing",
      description:
        "Share polls via social media, email, or direct links. Engage your audience and collect votes instantly.",
      // image: {
      //   src: "https://relume-assets.s3.amazonaws.com/placeholder-image-landscape.svg",
      //   alt: "Placeholder image 3",
      // },
    },
    {
      columnText: "04",
      verticalText: "Insightful Analysis",
      horizontalText: "Insightful Analysis",
      heading: "Analyze Results Instantly",
      description:
        "Analyze poll results as they come in. Make informed decisions quickly with clear, actionable data.",
      // image: {
      //   src: "https://relume-assets.s3.amazonaws.com/placeholder-image-landscape.svg",
      //   alt: "Placeholder image 4",
      // },
    },
  ],
};

Features.displayName = "Features";
