import React from 'react';
import { X, Plus } from 'lucide-react';
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { PlanFeatures } from '@/utils/plans';

interface CriteriaSelectionProps {
  criteria: string[];
  setCriteria: React.Dispatch<React.SetStateAction<string[]>>;
  customCriterion: string;
  setCustomCriterion: React.Dispatch<React.SetStateAction<string>>;
  toggleCriterion: (criterion: string) => void;
  addCustomCriterion: () => void;
  removeCustomCriterion: (criterion: string) => void;
  predefinedCriteria: string[];
  showCriteriaWarning: boolean;
  userPlan: PlanFeatures;
}

const CriteriaSelection: React.FC<CriteriaSelectionProps> = ({
  criteria,
  setCriteria,
  customCriterion,
  setCustomCriterion,
  toggleCriterion,
  addCustomCriterion,
  removeCustomCriterion,
  predefinedCriteria,
  showCriteriaWarning,
  userPlan,
}) => {
  return (
    <div>
      <h3 className="text-lg font-semibold mb-2">Voting Criteria</h3>
      <div className="flex flex-wrap items-start gap-2 mb-2">
        <ToggleGroup 
          type="multiple" 
          value={criteria} 
          onValueChange={setCriteria} 
          className="flex flex-wrap gap-2"
        >
          {predefinedCriteria.map((criterion) => (
            <ToggleGroupItem
              key={criterion}
              value={criterion}
              disabled={criteria.length >= userPlan.maxVotingCriteria && !criteria.includes(criterion)}
              onClick={() => toggleCriterion(criterion)}
              className="bg-transparent hover:bg-foreground/40 hover:text-background data-[state=on]:bg-foreground data-[state=on]:text-background transition-colors"
            >
              {criterion}
            </ToggleGroupItem>
          ))}
          {criteria.filter(c => !predefinedCriteria.includes(c)).map((criterion) => (
            <ToggleGroupItem
              key={criterion}
              value={criterion}
              className="bg-foreground text-background hover:bg-foreground/70 hover:text-background data-[state=on]:bg-foreground data-[state=on]:text-background transition-colors flex items-center"
            >
              {criterion}
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  removeCustomCriterion(criterion);
                }}
                className="ml-2 focus:outline-none"
                aria-label={`Remove ${criterion} criterion`}
              >
                <X size={14} className="text-secondary font-bold hover:text-destructive" />
              </button>
            </ToggleGroupItem>
          ))}
        </ToggleGroup>
      </div>
      <div className="flex items-center mt-2">
        <Input
          type="text"
          placeholder="Add custom criterion"
          value={customCriterion}
          onChange={(e) => setCustomCriterion(e.target.value)}
          className="flex-grow mr-2"
        />
        <Button 
          onClick={addCustomCriterion} 
          disabled={criteria.length >= userPlan.maxVotingCriteria || !customCriterion.trim()}
          aria-label="Add custom criterion"
          variant="default"
          className="!p-2 flex items-center justify-center"
        >
          <Plus size={20} />
        </Button>
      </div>
      {showCriteriaWarning && (
        <Alert className="mt-2">
          <AlertDescription>
            You've reached the maximum of {userPlan.maxVotingCriteria} criteria. Remove a criterion to add a new one.
          </AlertDescription>
        </Alert>
      )}
    </div>
  );
};

export default CriteriaSelection;